import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import Continue from './continue'
import PdfRenderButton from './pdf-download'

import headerArrowBG from '../../images/header_arrow_bg.svg'

const StageHeaderWrapper = styled(Row)`
  position: relative;
  @media (min-width: 1200px) {
    ${(props) =>
      props.isQuiz &&
      `
      background-color: rgb(235, 153, 18);
    `}
  }
  &:after {
    ${(props) => props.stage >= 2 && `content: "";`}
    position: absolute;
    top: 0;
    left: 50%;
    right: -105px;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    ${(props) => (props.stage === 2 || props.stage === 5) && `left: 70%;`}
    @media (max-width: 1599px) {
      /* ${(props) => props.stage === 2 && `content: none;`} */
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      left: 70%;
      right: -65px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      left: 70%;
      right: -65px;
    }
    @media (max-width: 991px) {
      content: none;
    }
  }
`

const StageHeaderCol = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.stage >= 2 &&
      `
      flex: 0 0 59.666667%!important;
      max-width: 59.666667%!important;
    `}
  }
  @media (min-width: 1200px) {
    ${(props) =>
      (props.stage === 2 || props.stage === 5) &&
      `
      flex: 0 0 66.666667%!important;
      max-width: 66.666667%!important;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.stage >= 2 &&
      `
      flex: 0 0 59.666667%!important;
      max-width: 59.666667%!important;
    `}
  }
  @media (min-width: 992px) {
    ${(props) =>
      props.stage >= 2 &&
      `
        flex: 0 0 50%;
          max-width: 50%;
      `}
  }
`

const StageHeaderColCommentary = styled(Col)`
  position: relative;
  display: flex;
  align-items: flex-end;
  background-color: #fcdfcd;
  margin-right: -105px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 20;
  ${(props) =>
    props.stage === 4 &&
    `
    // align-items: center;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  `}
  ${(props) =>
    props.isQuiz &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${headerArrowBG});
    background-repeat: no-repeat;
    background-position: 25px center;
    background-size: contain;
  `}
  @media (min-width: 1600px) {
    padding-left: 130px;
    ${(props) =>
      props.isQuiz &&
      `
    background-position: 85px center;
  `}
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.isQuiz &&
      `
    background-position: 35px center;
  `}
  }
  @media (min-width: 1200px) {
    ${(props) =>
      props.isQuiz &&
      `
    background-color: rgb(235, 153, 18);
    border-radius: 0;
  `}
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-right: -65px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-right: -65px;
    ${(props) =>
      props.isQuiz &&
      `
    margin-right: 15px;
    background-position: 10px center;
  `}
  }
  @media (min-width: 992px) {
    ${(props) =>
      props.stage === 4 &&
      `
        padding-top: 60px;
    `}
  }
  @media (max-width: 991px) {
    ${(props) =>
      props.stage !== 4 &&
      `
      display: none;
    `}
    ${(props) =>
      props.stage === 4 &&
      `
      flex: 0 0 100%;
      max-width: 100%;
    `}
  }
`

const StageHeaderColCommentaryOr = styled.span`
  position: absolute;
  top: 50%;
  left: -20px;
  display: inline-block;
  width: 70px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  @media (min-width: 1600px) {
    left: 55px;
    font-size: 40px;
    margin-top: -30px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    left: -38px;
    font-size: 22px;
    margin-top: -16px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    left: -38px;
    font-size: 22px;
    margin-top: -16px;
  }
  @media (max-width: 991px) {
    position: static;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const StageHeaderTitleCommentary = styled.h2`
  color: ${(props) => props.theme.textColorWhite};
  font-size: 29px;
  font-weight: 700;
  padding-left: 60px;
  ${(props) =>
    (props.stage === 2 || props.stage === 5) &&
    `
      padding-left: 60px;
    `}
  ${(props) =>
    props.stage === 4 &&
    `
      color: ${props.theme.textColor};
      font-size: 40px;
      text-align: center;
      padding-left: 0!important;
    `}
  ${(props) =>
    props.isQuiz &&
    `
    color: ${props.theme.textColorGrayBlack};
    padding-left: 0;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
    padding-left: 35px;
    ${(props) =>
      (props.stage === 2 || props.stage === 5) &&
      `
      padding-left: 45px;
    `}
    ${(props) => props.isQuiz && `padding-left: 0;`}
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px;
    padding-left: 35px;
    /* padding-right: 35px; */
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  font-size: 22px;
  }
  @media (max-width: 575px) {
  font-size: 20px;
  }
`

const StageHeaderSubTitleCommentary = styled.p`
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 19px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 19px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 19px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const StageHeaderContentWrapper = styled.div`
  text-align: center;
  padding-top: 30px;
  /* margin-top: 30px; */
  margin-left: 21%;
  margin-right: 21%;
  margin-bottom: 70px;
  border-bottom: 1px solid ${(props) => props.theme.colorOrangeRed};
  ${(props) =>
    props.isQuiz &&
    `
    position: relative;
    text-align: left;
    padding-left: 5px;
    padding-right: 15px;
    margin-bottom: 0;
  `}
  @media (min-width: 1200px) {
    ${(props) =>
      props.isQuiz &&
      `
    background-color: rgb(248, 174, 0);
  `}
  }
  @media (min-width: 992px) {
    ${(props) =>
      props.stage >= 2 &&
      `
        margin-left: 0;
        margin-right: 0;
        `}
    ${(props) =>
      props.stage === 4 &&
      `
        padding-top: 60px;
        padding-bottom: 35px;
        margin-bottom: 0;
    `}
    ${(props) =>
      props.isQuiz &&
      ` margin-left: 0;
      margin-right: 0;
      z-index: 50;
    `}
  }
  @media (max-width: 991px) {
    ${(props) =>
      props.isQuiz &&
      `
      padding-left: 15px;
    `};
    ${(props) =>
      props.stage === 4 &&
      `
        margin-bottom: 15px;
    `};
    margin-left: 0;
    margin-right: 0;
  }
  &:before {
    position: absolute;
    top: 0;
    left: calc(-100% - 15px);
    bottom: -1px;
    display: block;
    width: 500px;
    z-index: -100;
    @media (min-width: 1600px) {
      left: -70%;
    }
    @media (min-width: 1200px) {
      ${(props) =>
        props.isQuiz &&
        `
      background-color: rgb(248, 174, 0);
    `}
    }
    @media (min-width: 992px) {
      ${(props) =>
        props.isQuiz &&
        `
        content: "";
      `};
    }
  }
`

const StageHeaderTitle = styled.h2`
  font-size: 29px;
  font-weight: 700;
  ${(props) =>
    props.stage === 4 &&
    `
      font-size: 40px;
      text-align: center;
  `};
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
    ${(props) =>
      props.stage === 4 &&
      `
      font-size: 24px;
    `};
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
    ${(props) =>
      props.stage === 4 &&
      `
      font-size: 22px;
    `};
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const StageHeaderDesc = styled.p`
  font-size: 22px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 19px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 19px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 19px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const StageHeaderContinueWrapper = styled.div`
  padding-bottom: 30px;
  @media (max-width: 991px) {
    /* display: none; */
    margin-top: -20px;
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    margin-top: 0;
  }
  .continueButton {
    @media (max-width: 360px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`

const stageTitleDescMap = {
  1: {
    title:
      'Czy jesteś świadomy zagrożenia niealkoholowego stłuszczenia wątroby (NAFLD)?',
    description: `Jeśli powyższy skrót widzisz pierwszy raz, to oznacza tylko jedno - wiesz zdecydowanie za mało! Odpowiedz na kilka wstępnych pytań i przejdź dalej.`,
  },
  2: {
    title: 'Coś o Tobie',
    description: `Uzupełnij podstawowe dane aby sprawdzić czy możesz być w grupie ryzyka niealkoholowego stłuszczenia wątroby.`,
  },
  3: {
    title: 'Jak żyjesz na co dzień?',
    description: `Odpowiedz na poniższe pytania aby sprawdzić czy niealkoholowe stłuszczenie wątroby może dotyczyć również Ciebie.`,
  },
  4: {
    title: 'Zapoznaj się z wynikami testu',
    description:
      'Czy jesteś w grupie ryzyka niealkoholowego stłuszczenia wątroby?',
  },
  5: {
    title: 'Poznaj ryzyko NAFLD.',
    description: `Aby poznać ryzyko związane ze zdrowiem Twojej wątroby wyliczymy dwa czynniki - FIB-4 (wskaźnik oceny zwłóknienia wątroby) oraz APRI (AST-to-platelet ratio index).`,
  },
}

const quizTitleDesc = {
  1: {
    title: `Twoja wiedza na temat Niealkoholowej Stłuszczeniowej Choroby Wątroby jest niewielka.`,
    description: `Niestety, aby uchronić się przed zagrożeniem, musisz znacznie zwiększyć swoją świadomość. Zacznij już teraz!`,
  },
  2: {
    title: `Wygląda na to, że wyrażenie Nielkoholowa Stłuszczeniowa Choroba Wątroby obiło Ci się o uszy.`,
    description: `Jednak Twoja wiedza na temat zagrożenia może być znacznie większa. Dzięki niej zadbasz o swoją wątrobę w odpowiedni sposób.`,
  },
  3: {
    title: `Temat Niealkoholowego Stłuszczenia Wątroby zdecydowanie nie jest Ci obcy.`,
    description: `Jesteś na dobrej drodze, aby uniknąć zagrożenia i w pełni zatroszczyć się o swoje zdrowie. Czas dowiedzieć się więcej!`,
  },
  4: {
    title: `Twoja świadomość zagrożenia, jakim jest Niealkoholowa Stłuszczeniowa Choroba Wątroby, okazała się bardzo wysoka.`,
    description: `Wiesz, czego potrzebujesz, a czego powinieneś unikać, aby zatroszczyć się o właściwe funkcjonowanie Twojej wątroby.`,
  },
}

const StageHeader = (props) => {
  const stage = props.stage
  const percentAnswers = props.percentAnswers
  let quizOption = 1

  if (percentAnswers >= 20 && percentAnswers < 50) {
    quizOption = 2
  } else if (percentAnswers >= 50 && percentAnswers < 80) {
    quizOption = 3
  } else if (percentAnswers >= 80) {
    quizOption = 4
  } else {
    quizOption = 1
  }

  useEffect(() => {
    if (process.browser && typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'event',
        category: 'Stage Change',
        action: stage,
      })
    }
  }, [stage])
  console.log(props)
  return (
    <StageHeaderWrapper stage={stage} isQuiz={props.isQuiz}>
      <StageHeaderCol stage={stage}>
        <StageHeaderContentWrapper stage={stage} isQuiz={props.isQuiz}>
          {!props.isQuiz && stageTitleDescMap[stage].title && (
            <StageHeaderTitle stage={stage}>
              {stageTitleDescMap[stage].title}
            </StageHeaderTitle>
          )}
          {props.isQuiz && (
            <StageHeaderTitle>
              {quizTitleDesc[quizOption].title}
            </StageHeaderTitle>
          )}
          <StageHeaderDesc>
            {!props.isQuiz && stageTitleDescMap[stage].description}
            {props.isQuiz && quizTitleDesc[quizOption].description}

            {stage === 4 && <PdfRenderButton values={props.values}>
              <span>Ściągnij podsumowanie testu</span>
            </PdfRenderButton>}
          </StageHeaderDesc>
        </StageHeaderContentWrapper>
      </StageHeaderCol>
      {stage >= 2 && (
        <StageHeaderColCommentary stage={stage}>
          <div>
            {stage === 4 && (
              <StageHeaderColCommentaryOr>lub</StageHeaderColCommentaryOr>
            )}
            <StageHeaderTitleCommentary stage={stage}>
              {stage >= 2 && stage <= 3 && 'Komentarz'}
              {stage === 4 && <>Przejdź do kalkulatora.</>}
              {stage === 5 && 'Informacje dodatkowe'}
            </StageHeaderTitleCommentary>
            {stage === 4 && (
              <>
                <StageHeaderSubTitleCommentary>
                  Wykonaj dokładniejszą ocenę kondycji swojej wątroby.
                </StageHeaderSubTitleCommentary>
                <StageHeaderContinueWrapper>
                  <Continue stage={4} handleClick={props.submmitForm} />
                </StageHeaderContinueWrapper>
              </>
            )}
          </div>
        </StageHeaderColCommentary>
      )}
      {props.isQuiz && (
        <StageHeaderColCommentary isQuiz={props.isQuiz}>
          <StageHeaderTitleCommentary stage={stage} isQuiz={props.isQuiz}>
            Przejdź do&nbsp;następnego
            <br /> etapu aby sprawdzić stan
            <br /> swojej wątroby!
          </StageHeaderTitleCommentary>
        </StageHeaderColCommentary>
      )}
    </StageHeaderWrapper>
  )
}

export default StageHeader
