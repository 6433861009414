import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import StickyBox from 'react-sticky-box'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { FormHeaderCalculation } from './form-tags'
import {
  PollStageColHeaderCalculationMobile,
  PollStageColCalculation,
  PollStageCalculationWrapper,
  PollStageCalculationListDesc,
} from '../tags'

const StageCalculationWrapper = styled(PollStageCalculationWrapper)`
  @media (max-width: 575px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-top: 30px;
    `}
  }
  & > div {
    ${(props) => props.stage === 4 && `padding-bottom: 0!important;`}
  }
  h3 {
    font-weight: 700;
    font-size: 2rem;
    color: rgb(225, 70, 26);
  }
`

const StageCalculationDescWrapper = styled.div``

const Stage4CalculationDescWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding-bottom: 0 !important;
  @media (min-width: 992px) {
    padding-left: 25px;
  }
  p {
    font-size: 22px;
    font-weight: 300;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 18px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  strong {
    font-weight: 700;
  }
  ul {
    padding-left: 40px;
    margin-left: -18px;
    li {
      color: ${(props) => props.theme.colorOrangeDark};
      font-size: 22px;
      font-weight: 700;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 18px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 16px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 16px;
      }
      @media (max-width: 575px) {
        font-size: 14px;
      }
      span {
        color: ${(props) => props.theme.textColor};
      }
    }
  }
`

const TestCodeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  padding-top: 45px;
  padding-left: 75px;
  padding-right: 60px;
  padding-bottom: 50px !important;
  @media (min-width: 1600px) {
    margin-left: -75px;
    margin-right: -15px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 35px;
    padding-bottom: 40px !important;
    margin-left: -60px;
    margin-right: -15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 35px;
    padding-left: 60px;
    padding-right: 25px;
    padding-bottom: 40px !important;
    margin-left: -60px;
    margin-right: -15px;
  }
  @media (max-width: 991px) {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 35px !important;
    margin-left: -20px;
    margin-right: -15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-left: -20px;
  }
  @media (max-width: 575px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  p {
    margin-bottom: 5px;
  }
`

const TestCodeCopyButton = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${(props) => props.theme.colorWhite};
  border: 4px solid rgb(249, 141, 0);
  border-radius: 10px;
  padding-right: 20px;
  padding-bottom: 0 !important;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-right: 10px;
    border: 3px solid rgb(249, 141, 0);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 10px;
    border: 3px solid rgb(249, 141, 0);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: 15px;
    border: 3px solid rgb(249, 141, 0);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-right: 15px;
    border: 3px solid rgb(249, 141, 0);
  }
  @media (max-width: 575px) {
    padding-right: 15px;
    border: 2px solid rgb(249, 141, 0);
  }

  .testCode {
    width: 100%;
    background-image: url(${require('../../images/copy_text.svg')});
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 90px auto;
    display: inline-block;
    color: #b84b3f;
    font-size: 29px;
    font-weight: 700;
    text-align: center;
    padding-right: 100px;
    padding-top: 15px;
    padding-bottom: 15px !important;
    @media (min-width: 1200px) and (max-width: 1599px) {
      background-size: 55px auto;
      font-size: 22px;
      padding-right: 55px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      background-size: 45px auto;
      font-size: 18px;
      padding-right: 45px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 25px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      background-size: 65px auto;
      font-size: 25px;
      padding-right: 65px;
    }
    @media (max-width: 575px) {
      background-size: 50px auto;
      font-size: 16px;
      padding-right: 50px;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
    &.copied {
      opacity: 0.8;
    }
  }
`

const StageColHeaderCalculationMobile = styled(
  PollStageColHeaderCalculationMobile
)`
  @media (max-width: 991px) {
    ${(props) => props.visibility === 'false' && `display: none;`}
  }
`

const StageColCalculation = styled(PollStageColCalculation)`
  @media (max-width: 991px) {
    ${(props) => props.visibility === 'false' && `display: none;`}
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.stage === 4 &&
      `
      display: block;
    `}
  }
`

const StageCalculationListDesc = styled(PollStageCalculationListDesc)``

export default ({ values, header, prefix, stage, docId }) => {
  const [BMI, setBMI] = useState(null)
  const [alcoholGrams, setAlcoholGrams] = useState(0)
  const [isCopied, setIsCopied] = useState(false)

  const setCopied = () => setIsCopied(true)

  let conditionShowComments = false

  useEffect(() => {
    // calculate BMI
    if (
      values.weight &&
      values.height &&
      parseInt(values.weight, 10) > 0 &&
      parseInt(values.height, 10) > 0
    ) {
      let heightMeters = values.height / 100
      setBMI(parseFloat(values.weight / (heightMeters * heightMeters)))
    } else {
      setBMI(null)
    }

    // calculate alcohol grams
    let newAlcoholGrams = 0
    if (values.beer && values.beer > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.beer) * 21.5
    if (values.vine400 && values.vine400 > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.vine400) * 14.8
    if (values.vine750 && values.vine750 > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.vine750) * 74
    if (values.drink && values.drink > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.drink) * 16
    if (values.alkohol100 && values.alkohol100 > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.alkohol100) * 31.5
    if (values.alkohol700 && values.alkohol700 > 0)
      newAlcoholGrams = newAlcoholGrams + parseFloat(values.alkohol700) * 220
    setAlcoholGrams(newAlcoholGrams)
  }, [values])

  conditionShowComments = new Boolean(
    header ||
      (values.gender && values.gender === 'mężczyzna') ||
      BMI ||
      (values.weightChange && values.weightChange !== 'nie zmieniła się') ||
      (values.physicalActivity && BMI) ||
      values.sleepDay ||
      (alcoholGrams > 0 && values.gender) ||
      values.smokingCigarette ||
      (values.ultrasound && values.ultrasound === 'tak' && values.steatosis) ||
      (values.symptoms && values.symptoms.length > 0) ||
      (values.additionalDiseases && values.additionalDiseases.length > 0)
  ).toString()

  return (
    <>
      <StageColHeaderCalculationMobile
        xs={12}
        visibility={conditionShowComments}
      >
        <FormHeaderCalculation>
          {header ? header : `Komentarz`}
        </FormHeaderCalculation>
      </StageColHeaderCalculationMobile>
      <StageColCalculation
        stage={stage}
        visibility={conditionShowComments}
        className="stageColCalculation"
      >
        <StageCalculationWrapper stage={stage}>
          <StickyBox offsetTop={20} offsetBottom={0}>
            {stage !== 4 && (
              <StageCalculationDescWrapper>
                {prefix ? prefix : null}
                <StageCalculationListDesc>
                  {values.gender && values.gender === 'mężczyzna' && (
                    <li>
                      <p className="title">
                        Twoja płeć:{' '}
                        <span className="title-value">{values.gender}</span>
                      </p>
                      <p>
                        Stłuszczeniowa choroba wątroby jest częstsza u mężczyzn
                        niż u kobiet. Częściowo tłumaczy się to tym, że
                        mężczyźni mają średnio wyższe BMI oraz tym, że piją
                        więcej alkoholu niż kobiety.
                      </p>
                    </li>
                  )}

                  {BMI && (
                    <li>
                      <p className="title">
                        Wyliczone BMI:{' '}
                        <span className="title-value">{BMI.toFixed(1)}</span>
                      </p>
                      <p>
                        {BMI < 25 && (
                          <>
                            Twoje BMI mieści się w&nbsp;przedziale prawidłowej
                            wagi ciała. Pamiętaj, że oprócz BMI ważne jest, aby
                            utrzymywać w&nbsp;normie również obwód w&nbsp;talii.
                          </>
                        )}
                        {BMI >= 25 && BMI < 30 && (
                          <>
                            Wyliczone BMI wskazuje na&nbsp;nadwagę, która jest
                            jednym z&nbsp;najważniejszych czynników ryzyka
                            stłuszczeniowej choroby wątroby. W&nbsp;przypadku
                            muskularnej budowy ciała podwyższone BMI nie musi
                            koniecznie oznaczać zwiększonego ryzyka
                            stłuszczeniowej choroby wątroby. Decydujący jest
                            obwód w&nbsp;talii.
                          </>
                        )}
                        {BMI >= 30 && BMI < 40 && (
                          <>
                            Twój wskaźnik BMI wskazuje na&nbsp;otyłość, która
                            jest najważniejszym czynnikiem ryzyka
                            stłuszczeniowej choroby wątroby.
                          </>
                        )}
                        {BMI >= 40 && (
                          <>
                            Wskaźnik BMI wskazuje na&nbsp;otyłość olbrzymią,
                            która jest silnie związana ze&nbsp;stłuszczeniową
                            chorobą wątroby, cukrzycą, nadciśnieniem tętniczym
                            i&nbsp;ryzykiem sercowo-naczyniowym.
                          </>
                        )}
                      </p>
                    </li>
                  )}

                  {values.weightChange &&
                    values.weightChange !== 'nie zmieniła się' && (
                      <li>
                        <>
                          <p className="title">
                            Zmiana masy Twojego ciała (ostatnie 2 lata):{' '}
                            <span className="title-value">
                              {values.weightChange}
                            </span>
                          </p>
                          {values.weightChange === 'wzrosła >5 kg' && (
                            <p>
                              Wzrost Twojej wagi o ponad 5 kg w ciągu ostatnich
                              2 lat stanowi istotny czynnik ryzyka rozwoju
                              stłuszczeniowej choroby wątroby, szczególnie jeśli
                              przyrost masy ciała jest związany z odkładaniem
                              się tłuszczu w obrębie brzucha, czyli w postaci
                              tzw. „otyłości brzusznej”.
                            </p>
                          )}
                          {values.weightChange === 'wzrosła 0-5 kg' && (
                            <p>
                              Wzrost Twojej wagi w ostatnich 2 latach mógł
                              stanowić dość duże obciążenie dla wątroby,
                              szczególnie jeśli towarzyszył mu wzrost obwodu w
                              talii. Może to prowadzić do stłuszczenia wątroby.
                            </p>
                          )}
                          {values.weightChange === 'spadła 0-5 kg' && (
                            <p>
                              Spadek masy ciała jest korzystny i potrafi
                              odwrócić z biegiem czasu proces stłuszczeniowy,
                              ale badania naukowe wskazują, że konieczna jest
                              utrata przynajmniej 7-10% pierwotnej wagi.
                            </p>
                          )}
                          {values.weightChange === 'spadła >5 kg' && (
                            <p>
                              Spadek masy ciała jest bardzo korzystny dla
                              wątroby, ale należy unikać zbyt intensywnego
                              ubytku masy ciała związanego często z dietami
                              eliminacyjnymi. Zbyt szybkie chudnięcie jest
                              również czynnikiem ryzyka stłuszczenia wątroby.
                            </p>
                          )}
                        </>
                      </li>
                    )}

                  {values.physicalActivity && BMI && (
                    <li>
                      <>
                        <p className="title">
                          Tygodniowa aktywność fizyczna:{' '}
                          <span className="title-value">
                            {values.physicalActivity}
                          </span>
                        </p>
                        {values.physicalActivity === '<30 min' && BMI < 25 && (
                          <p>
                            Pomimo, że nie masz nadwagi, ani otyłości, to
                            wysiłek fizyczny może pomóc utrzymać Ci sprawność.
                            Ponadto aktywność fizyczna zmniejsza stężenie
                            glukozy we krwi i obniża stężenie cholesterolu.
                            Aktywność fizyczna redukuje również tendencję do
                            tycia i rozwoju niealkoholowej stłuszczeniowej
                            choroby wątroby, która może się rozwijać na skutek
                            zwalniającego z wiekiem tempa metabolizmu.
                          </p>
                        )}
                        {values.physicalActivity === '<30 min' &&
                          BMI >= 25 &&
                          BMI < 35 && (
                            <p>
                              Aktywność fizyczna jest znakomitym uzupełnieniem
                              diety w walce z nadmiarowymi kilogramami. Zwiększy
                              ona zarówno ogólną sprawność Twojego ciała, jak i
                              wydatek energetyczny, prowadząc do
                              korzystniejszego bilansu kalorycznego. W leczeniu
                              niealkoholowego stłuszczenia wątroby Wytyczne
                              Europejskiego Towarzystwa Badań nad Chorobami
                              Wątroby zalecają 150-200 min umiarkowanego wysiłku
                              fizycznego na tydzień w 3-5 sesjach (np. szybkie
                              spacery, rower - również stacjonarny).
                            </p>
                          )}
                        {values.physicalActivity === '<30 min' && BMI >= 35 && (
                          <p>
                            Aktywność fizyczna jest znakomitym uzupełnieniem
                            diety w walce z nadmiarowymi kilogramami oraz
                            niealkoholową stłuszczeniową chorobą wątroby.
                            Chociaż początkowo nie będzie Ci łatwo, to warto
                            zaangażować się w program stopniowo zwiększanej
                            aktywności fizycznej. Zwiększy ona wydatek
                            energetyczny prowadząc do korzystniejszego bilansu
                            kalorycznego. Wytyczne Europejskiego Towarzystwa
                            Badań nad Chorobami Wątroby zalecają 150-200 min
                            umiarkowanego wysiłku fizycznego na tydzień w 3-5
                            sesjach (np. szybkie spacery, rower - również
                            stacjonarny).
                          </p>
                        )}

                        {values.physicalActivity === '30 min-60 min' &&
                          BMI < 25 && (
                            <p>
                              Wysiłku fizycznego w Twoim życiu jest nieco za
                              mało. Wytyczne Europejskiego Towarzystwa Badań nad
                              Chorobami Wątroby zalecają 150-200 min
                              umiarkowanego wysiłku fizycznego na tydzień w 3-5
                              sesjach (np. szybkie spacery, rower - również
                              stacjonarny).
                            </p>
                          )}
                        {values.physicalActivity === '30 min-60 min' &&
                          BMI >= 25 && (
                            <p>
                              Ilość czasu, który tygodniowo przeznaczasz na
                              aktywność fizyczną jest nieco za mała w świetle
                              zaleceń Europejskiego Towarzystwa Badań nad
                              Chorobami Wątroby. Obok diety i ograniczenia
                              spożycia alkoholu, aktywność fizyczna jest jednym
                              z podstawowych sposobów radzenia sobie ze
                              stłuszczeniem wątroby. Zalecane jest 150-200 min
                              umiarkowanego wysiłku fizycznego na tydzień w 3-5
                              sesjach (np. szybkie spacery, rower - również
                              stacjonarny).
                            </p>
                          )}

                        {values.physicalActivity === '60-90 min' &&
                          BMI < 25 && (
                            <p>
                              Chociaż Twoje BMI jest niskie, żeby to utrzymać, z
                              biegiem lat trzeba będzie wkładać w to coraz
                              więcej wysiłku. Zalecana tygodniowa ilość
                              aktywności fizycznej to 150-200 min w 3-5 sesjach,
                              czyli nieco więcej niż w tej chwili poświęcasz
                              tygodniowo na wysiłek fizyczny.
                            </p>
                          )}
                        {values.physicalActivity === '60-90 min' &&
                          BMI >= 25 && (
                            <p>
                              Ilość czasu, który tygodniowo poświęcasz na
                              aktywność fizyczną jest nieco za niska. Jeśli
                              chcesz wrócić do prawidłowej masy ciała, wskazane
                              jest konsekwentne prowadzenie niskokalorycznej
                              diety oraz zwiększenie aktywności fizycznej do
                              150-200 min.
                            </p>
                          )}

                        {values.physicalActivity === '90-150 min' &&
                          BMI < 25 && (
                            <p>
                              Na wysiłek fizyczny poświęcasz zalecaną tygodniową
                              ilość czasu. Znakomicie! Pamiętaj, jednak o tym,
                              żeby pilnować diety i w ten sposób kontrolować
                              swoją masę ciała i przeciwdziałać rozwojowi
                              niealkoholowej stłuszczeniowej choroby wątroby.
                            </p>
                          )}
                        {values.physicalActivity === '90-150 min' &&
                          BMI >= 25 && (
                            <p>
                              Ilość czasu, jaką tygodniowo poświęcasz na
                              aktywność fizyczną jest prawidłowa. Pamiętaj
                              jednak, że aby dążyć do prawidłowej masy ciała
                              należy jednocześnie pilnować diety oraz utrzymać
                              aktywność fizyczną na tym poziomie. Szczególnie
                              zalecane jest ponad 2,5h/tydzień aktywności typu
                              kardio, związanej z dużą ilością powtórzeń, bez
                              intensywnych obciążeń.
                            </p>
                          )}
                      </>
                    </li>
                  )}

                  {values.sleepDay && (
                    <li>
                      <>
                        <p className="title">
                          Średnia dobowa długość snu:{' '}
                          <span className="title-value">{values.sleepDay}</span>
                        </p>
                        {values.sleepDay === '<6h' && (
                          <p>
                            Śpisz zdecydowanie za krótko w stosunku do ilości
                            snu określanej jako zdrowa. Każda godzina dodana do
                            czasu nieprzerwanego snu zmniejsza częstość
                            występowania otyłości o 30%!
                          </p>
                        )}
                        {values.sleepDay === '6-7,5h' && (
                          <p>
                            Mniej niż 7,5 godzin snu to mniej niż zalecana ilość
                            snu dla osoby dorosłej. Co ważniejsze jednak, zbyt
                            krótki sen sprzyja otyłości i tym samym stłuszczeniu
                            wątroby. Warto spróbować wydłużyć sen, gdyż każda
                            godzina snu dłużej zmniejsza częstość występowania
                            otyłości o 30%.
                          </p>
                        )}
                        {values.sleepDay === '7-9,5h' && (
                          <p>
                            Poświęcasz na sen odpowiednią ilość czasu. Tym samym
                            robisz tyle ile można, żeby zmniejszyć ryzyko
                            otyłości, gdyż wykazano, że zbyt krótki lub zbyt
                            długi sen sprzyja przyrostowi masy ciała.
                          </p>
                        )}
                        {values.sleepDay === 'powyżej 9 godzin' && (
                          <p>
                            Badania naukowe pokazują, że istotnym czynnikiem
                            ryzyka jest zbyt krótki sen. W tych samych badaniach
                            zauważono również, że sen zbyt długi, przekraczający
                            9 godzin również zwiększa ryzyko otyłości. Wskazana
                            wydaje się redukcja ilości snu w ciągu doby do ok.
                            7,5-8 godzin.
                          </p>
                        )}
                      </>
                    </li>
                  )}

                  {alcoholGrams > 0 && values.gender && (
                    <li>
                      <>
                        <p className="title">
                          Tygodniowe spożycie alkoholu:{' '}
                          <span className="title-value">
                            {alcoholGrams.toFixed(0)} g etanolu
                          </span>
                        </p>
                        {alcoholGrams < 70 && values.gender === 'kobieta' && (
                          <p>
                            Spożywasz bezpieczną ilość alkoholu, która w
                            przypadku braku innych czynników wpływających
                            niekorzystnie na stan wątroby nie powinna powodować
                            negatywnych skutków dla wątroby. Pamiętaj jednak, że
                            kobiety mają około 2-krotnie większą wrażliwość na
                            toksyczne działanie alkoholu.
                          </p>
                        )}
                        {alcoholGrams >= 70 &&
                          alcoholGrams < 140 &&
                          values.gender === 'kobieta' && (
                            <p>
                              Kobiety mają około 2-krotnie większą wrażliwość na
                              toksyczne działanie alkoholu. Niezależnie jak
                              bardzo są odporne na euforyzujące działanie
                              alkoholu, do poważnego uszkodzenia wątroby
                              dochodzą średnio znacznie szybciej niż mężczyźni.
                              Spożywasz umiarkowane ilości alkoholu, ale
                              niewiele brakuje do przekroczenia granicy, powyżej
                              której znacznie wzrasta ryzyko hepatotoksycznego
                              wpływu alkoholu.
                            </p>
                          )}
                        {alcoholGrams >= 140 &&
                          alcoholGrams < 210 &&
                          values.gender === 'kobieta' && (
                            <p>
                              Kobiety mają około 2-krotnie większą wrażliwość na
                              toksyczne działanie alkoholu. Niezależnie jak
                              bardzo są odporne na euforyzujące działanie
                              alkoholu, do poważnego uszkodzenia wątroby
                              dochodzą średnio znacznie szybciej niż mężczyźni.
                              Ilość alkoholu, którą spożywasz stanowi ryzyko dla
                              wątroby. Rozsądne jest ograniczenie spożywania
                              alkoholu.
                            </p>
                          )}
                        {alcoholGrams >= 210 && values.gender === 'kobieta' && (
                          <p>
                            Kobiety mają około 2-krotnie większą wrażliwość na
                            toksyczne działanie alkoholu. Niezależnie jak bardzo
                            są odporne na euforyzujące działanie alkoholu, do
                            poważnego uszkodzenia wątroby dochodzą średnio
                            znacznie szybciej niż mężczyźni. Pijesz dużo
                            alkoholu i jesteś w grupie ryzyka rozwoju
                            alkoholowego stłuszczenia wątroby, alkoholowego
                            zapalenia wątroby, a z biegiem lat również
                            alkoholowej marskości wątroby. Wskazane wydaje się
                            zdecydowane ograniczenie ilości spożywanego
                            alkoholu.
                          </p>
                        )}

                        {alcoholGrams < 140 &&
                          values.gender === 'mężczyzna' && (
                            <p>
                              Spożywasz bezpieczną ilość alkoholu, która w
                              przypadku braku innych czynników wpływających
                              niekorzystnie na stan wątroby, nie powinna
                              powodować negatywnych skutków dla wątroby. Jeśli
                              jednak jesteś chory na inne choroby wątroby, takie
                              jak np. wirusowe zapalenia, każda ilość alkoholu
                              wpływa na wątrobę zdecydowanie niekorzystnie.
                            </p>
                          )}
                        {alcoholGrams >= 140 &&
                          alcoholGrams < 210 &&
                          values.gender === 'mężczyzna' && (
                            <p>
                              Chociaż spożywasz istotną dla wątroby ilość
                              alkoholu, to mieścisz się w bezpiecznych limitach
                              wyznaczonych dla osób bez innych chorób wątroby.
                            </p>
                          )}
                        {alcoholGrams >= 210 &&
                          alcoholGrams < 420 &&
                          values.gender === 'mężczyzna' && (
                            <p>
                              Ilość alkoholu, którą spożywasz tygodniowo
                              przekracza przedział bezpieczny i nieszkodliwy.
                              Wykazano, że takie ilości wiążą się ze
                              zdecydowanie wzrastającym ryzykiem rozwoju
                              alkoholowej marskości wątroby. Rozsądne jest
                              ograniczenie spożywania alkoholu.
                            </p>
                          )}
                        {alcoholGrams >= 420 &&
                          values.gender === 'mężczyzna' && (
                            <p>
                              Twoje tygodniowe spożycie alkoholu stanowi duże
                              ryzyko dla wątroby. Zdecydowanie przyspiesza
                              przebieg innych chorób i może z biegiem lat
                              spowodować alkoholową marskość wątroby. Intensywne
                              spożywanie alkoholu w krótkim czasie może również
                              wywołać zagrażające życiu alkoholowe zapalenie
                              wątroby, które może rozwinąć się u chorych bez
                              marskości wątroby.
                            </p>
                          )}
                      </>
                    </li>
                  )}

                  {values.smokingCigarette && (
                    <li>
                      <>
                        <p className="title">
                          Liczba papierosów wypalanych dziennie:{' '}
                          <span className="title-value">
                            {values.smokingCigarette}
                          </span>
                        </p>
                        {values.smokingCigarette === 'nie palę' && (
                          <p>
                            To, że nie palisz jest korzystne. Palenie jest
                            związane ze zwiększonym ryzykiem chorób
                            nowotworowych, w tym raka wątroby oraz sprzyja
                            rozwojowi miażdżycy i zwiększa ryzyko incydentów
                            sercowo-naczyniowych u chorych z niealkoholową
                            stłuszczeniową chorobą wątroby.
                          </p>
                        )}
                        {values.smokingCigarette === '<5 papierosów' && (
                          <p>
                            Liczba papierosów, które średnio dziennie wypalasz
                            nie jest duża, ale nawet taka ich liczba jest
                            związana ze zwiększonym ryzykiem nowotworów i może
                            zwiększać ryzyko sercowo-naczyniowe. Warto rozważyć
                            całkowite zaprzestanie palenia.
                          </p>
                        )}
                        {values.smokingCigarette === '5-15' && (
                          <p>
                            Palone przez Ciebie papierosy zwiększają ryzyko
                            nowotworowe, w tym ryzyko raka wątroby oraz
                            sprzyjają rozwojowi miażdżycy i zwiększają ryzyko
                            incydentów sercowo-naczyniowych, które w przypadku
                            współistnienia niealkoholowej stłuszczeniowej
                            choroby wątroby jest znacznie wyższe niż u osób
                            zdrowych.
                          </p>
                        )}
                        {values.smokingCigarette === '15-25' && (
                          <p>
                            Palone przez Ciebie papierosy zwiększają ryzyko
                            nowotworowe, w tym ryzyko raka wątroby oraz
                            sprzyjają rozwojowi miażdżycy i zwiększają ryzyko
                            incydentów sercowo-naczyniowych, które w przypadku
                            współistnienia niealkoholowej stłuszczeniowej
                            choroby wątroby jest znacznie wyższe niż u osób
                            zdrowych.
                          </p>
                        )}
                        {values.smokingCigarette === '>25 papierosów' && (
                          <p>
                            Palisz bardzo dużo i jest to związane ze
                            zdecydowanie zwiększonym ryzykiem chorób
                            nowotworowych, w tym raka wątroby. Ten nałóg jest
                            również związany z przyspieszeniem przebiegu
                            miażdżycy i zwiększonym ryzykiem
                            sercowo-naczyniowym. Jeśli chorujesz na
                            niealkoholową stłuszczeniową chorobę wątroby to
                            ryzyko jest wysokie, a palenie je w znacznym stopniu
                            zwiększa.
                          </p>
                        )}
                      </>
                    </li>
                  )}

                  {values.ultrasound &&
                    values.ultrasound === 'tak' &&
                    values.steatosis && (
                      <li>
                        <>
                          <p className="title">
                            USG jamy brzusznej (ostatnie 2 lata):{' '}
                            <span className="title-value">
                              {values.ultrasound}
                            </span>
                          </p>
                          {values.steatosis === 'nie' && (
                            <p>
                              Świetnie, że w USG nie widać stłuszczenia.
                              Pamiętaj jednak, że to nie oznacza, że go tam nie
                              ma. USG wykrywa stłuszczenie dopiero gdy
                              stłuszczone jest min. 30% komórek wątrobowych.
                            </p>
                          )}
                          {values.steatosis === 'tak' && (
                            <p>
                              Masz stłuszczenie wątroby w obrazie USG. Nie każde
                              stłuszczenie jest związane z postępem choroby do
                              marskości i jej objawów, dlatego warto byłoby
                              sprawdzić czy masz łagodną czy agresywną postać
                              stłuszczenia.
                            </p>
                          )}
                        </>
                      </li>
                    )}

                  {values.symptoms && values.symptoms.length > 0 && (
                    <li>
                      <p className="title">Odczuwane przez Ciebie objawy</p>
                      <p>
                        Skarżysz się na {values.symptoms.join(', ')}. Według
                        badań te objawy są często zgłaszane przez chorych z
                        niealkoholową stłuszczeniową chorobą wątroby i istotnie
                        obniżają jakość życia chorych ze stłuszczeniem.
                      </p>
                    </li>
                  )}

                  {values.additionalDiseases &&
                    values.additionalDiseases.length > 0 && (
                      <li>
                        <>
                          <p className="title">Choroby dodatkowe</p>
                          {values.additionalDiseases.includes(
                            'Nadciśnienie tętnicze'
                          ) && (
                            <p>
                              Chorujesz na nadciśnienie tętnicze, podobnie jak
                              30-60% chorych ze stłuszczeniem wątroby.{' '}
                            </p>
                          )}
                          {values.additionalDiseases.includes(
                            'Cukrzyca typu 2'
                          ) && (
                            <p>
                              Obecność cukrzycy typu 2 jest silnie związana z
                              niealkoholową stłuszczeniową chorobą wątroby.
                              20-30% chorych ze stłuszczeniową chorobą wątroby
                              ma cukrzycę oraz ponad 60% chorych z cukrzycą typu
                              2 cierpi na niealkoholową stłuszczeniową chorobę
                              wątroby.
                            </p>
                          )}
                          {values.additionalDiseases.includes(
                            'Hipercholesterolemia'
                          ) && (
                            <p>
                              Hipercholesterolemia występuje u ponad 70% chorych
                              ze stłuszczeniową chorobą wątrobą.
                            </p>
                          )}
                        </>
                      </li>
                    )}
                </StageCalculationListDesc>
              </StageCalculationDescWrapper>
            )}
            {stage === 4 && (
              <Stage4CalculationDescWrapper>
                <div>
                  <p>
                    W tym celu potrzebować będziesz wyników następujących badań:
                  </p>
                  <ul>
                    <li>
                      <span>ALT</span>
                    </li>
                    <li>
                      <span>AST</span>
                    </li>
                    <li>
                      <span>liczba płytek (PLT) w setkach tys.</span>
                    </li>
                  </ul>

                  <p>
                    Jeżeli nie dysponujesz aktualnymi wynikami badań, to zawsze
                    możesz wrócić do testu bez konieczności wypełniania ankiety
                    od nowa — wystarczy, że na stronie startowej podasz ten
                    unikalny kod:
                  </p>
                </div>
                <TestCodeWrapper>
                  <p>
                    <strong>Twój unikalny kod:</strong>
                  </p>
                  <TestCodeCopyButton>
                    <CopyToClipboard onCopy={setCopied} text={docId}>
                      <span className={`testCode${isCopied ? ' copied' : ''}`}>
                        {docId}
                      </span>
                    </CopyToClipboard>
                  </TestCodeCopyButton>
                </TestCodeWrapper>
              </Stage4CalculationDescWrapper>
            )}
          </StickyBox>
        </StageCalculationWrapper>
      </StageColCalculation>
    </>
  )
}
