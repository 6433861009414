import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'reactstrap'

import {
  CollapseWrapper,
  CollapseTitle,
  CollapseTitleWrapper,
  CollapseCard,
  CollapseCardBody,
} from '../tags'

const NotesStage5Wrapper = styled.div`
  margin-top: 0;
`

const NotesStage5CollapseWrapper = styled(CollapseWrapper)``

const NotesStage5Title = styled(CollapseTitle)`
  font-size: 26px !important;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 22px !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px !important;
  }
  @media (max-width: 575px) {
    font-size: 17px !important;
  }
`

const NotesStage5TitleWrapper = styled(CollapseTitleWrapper)``

const NotesStage5Card = styled(CollapseCard)`
  background-color: ${(props) => props.theme.colorPeach};
`

const NotesStage5CardBody = styled(CollapseCardBody)`
  /* padding-left: 0px!important;
  padding-right: 0px!important; */
  font-size: 22px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  p {
    font-weight: 400;
    &.title {
      font-size: 29px;
      font-weight: 700;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 24px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 24px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 24px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 20px;
      }
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 5px !important;
    li {
      &:before {
        content: '-';
        position: relative;
        left: -5px;
      }
    }
  }
`

const NotesStage5 = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <NotesStage5Wrapper>
      <NotesStage5CollapseWrapper>
        <NotesStage5Title onClick={toggle} carret={isOpen.toString()}>
          <NotesStage5TitleWrapper dataOpen={isOpen}>
            Piśmiennictwo
          </NotesStage5TitleWrapper>
        </NotesStage5Title>
        <Collapse isOpen={isOpen}>
          <NotesStage5Card>
            <NotesStage5CardBody>
              <p className="title">FIB-4</p>
              <ul>
                <li>
                  Shah AG, Lydecker A, Murray K, et al. Comparison of
                  noninvasive markers of fibrosis in patients with nonalcoholic
                  fatty liver disease. Clin Gastroenterol Hepatol.
                  2009;7(10):1104-12.
                </li>
              </ul>
              <p className="title">APRI</p>
              <ul>
                <li>
                  Lin ZH, Xin YN, Dong QJ, et al. Performance of the aspartate
                  aminotransferase-to-platelet ratio index for the staging of
                  hepatitis C-related fibrosis: an updated meta-analysis.
                  Hepatology. 2011;53:726-36.
                </li>
                <li>
                  Khan DA, Fatima Tuz Z, Khan FA, Mubarak A. Evaluation of
                  diagnostic accuracy of APRI for prediction of fibrosis in
                  hepatitis C patients. J Ayub Med Coll Abbottabad 2008; 20:
                  122-126.
                </li>
                <li>
                  Wai CT, Greenson JK, Fontana RJ, i in. A simple noninvasive
                  index can predict both signiﬁcant ﬁbrosis and cirrhosis in
                  patients with chronic hepatitis C. Hepatology.
                  2003;38:518–526.
                </li>
              </ul>
            </NotesStage5CardBody>
          </NotesStage5Card>
        </Collapse>
      </NotesStage5CollapseWrapper>
    </NotesStage5Wrapper>
  )
}

export default NotesStage5
