import React from 'react'
import styled from 'styled-components'

import StagesHeader from './stage-header'
import Stage1 from './stage1'
import Stage2 from './stage2'
import Stage3 from './stage3'
import Stage4 from './stage4'
import Stage5 from './stage5'

const StagesWrapper = styled.div`
  position: relative;
  margin-top: -20px;
`

const Stages = (props) => {
  const stageProps = {
    stage: props.stage,
    addValues: props.addValues,
    nextStage: props.nextStage,
    docId: props.docId,
    setDocId: props.setDocId,
    values: props.values,
    header: (submitForm = null) => {
      return (
        <StagesHeader
          stage={props.stage}
          isQuiz={props.isQuiz}
          percentAnswers={props.percentAnswers}
          submmitForm={submitForm}
          values={props.values}
        />
      )
    },
  }

  return (
    <StagesWrapper>
      {props.stage === 1 && (
        <Stage1
          isQuiz={props.isQuiz}
          setQuizAnsvers={props.setQuizAnsvers}
          setRightAnsweredQuestions={props.setRightAnsweredQuestions}
          setQuizPercentAnswers={props.setQuizPercentAnswers}
          {...stageProps}
        />
      )}
      {props.stage === 2 && <Stage2 {...stageProps} />}
      {props.stage === 3 && <Stage3 {...stageProps} />}
      {props.stage === 4 && <Stage4 {...stageProps} />}
      {props.stage === 5 && <Stage5 {...stageProps} />}
    </StagesWrapper>
  )
}

export default Stages
