import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  FormGroupWrapper,
  FormHeader,
  FormHeaderCalculation,
  FormCustomCalculationInput,
  FormDescWrapper,
  FormItemLacationWrapper,
  FormTextHelper,
} from './form-tags'
import {
  PollStageWrapper,
  PollStageCol,
  PollStageContentWrapper,
  PollStageColCalculation,
  PollStageCalculationWrapper,
  PollStageCalculationListDesc,
  PollStageColHeaderCalculationMobile,
  StageContainer,
} from '../tags'
import NotesStage5 from './notes-stage5'
import PdfRenderButton from './pdf-download'

import CalculatorHTML from '../../FIB-4-LEK.html'

const CalculatorWrapper = styled.div`
  display: none;
  visibility: hidden;
`

const StageWrapper = styled(PollStageWrapper)``

const StageCol = styled(PollStageCol)``

const StageContentWrapper = styled(PollStageContentWrapper)``

const StageColCalculation = styled(PollStageColCalculation)``

const StageCalculationWrapper = styled(PollStageCalculationWrapper)``

const StageCalculationListDesc = styled(PollStageCalculationListDesc)``

const StageCalculationFormGroupWrapper = styled(FormGroupWrapper)`
  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const StageFormDescWrapper = styled(FormDescWrapper)`
  &:last-of-type {
    margin-top: 40px;
  }

  p {
    font-weight: 400;
  }
`

const FormItemLacationWrapperInput = styled(FormItemLacationWrapper)`
  input {
    margin-bottom: 15px;
  }
`

const FormItemLacationWrapperInputLast = styled(FormItemLacationWrapperInput)`
  @media (min-width: 1200px) {
    flex: 0 0 35.8%;
    max-width: 35.8%;
  }
`

const StageHeader = (props) => {
  useEffect(() => {
    const calc = require('../../FIB-4-LEK.js')
    window.eval(calc)

    if (
      props.values.age &&
      props.values.ast &&
      props.values.alt &&
      props.values.plt
    ) {
      setScoreFIB4(
        getScoreFIB4(
          props.values.age,
          props.values.ast,
          props.values.alt,
          props.values.plt
        )
      )
    }

    if (props.values.ast && props.values.ncm2 && props.values.plt) {
      setScoreAPRI(
        getScoreAPRI(props.values.ast, props.values.ncm2, props.values.plt)
      )
    }
  }, [])

  const [scoreFIB4, setScoreFIB4] = useState([0, null])
  const getScoreFIB4 = (age, ast, alt, plt) => {
    window.wiek = parseInt(age, 10)
    window.AST = parseInt(ast, 10)
    window.ALT = parseInt(alt, 10)
    window.PLT = parseInt(plt, 10)
    window.pisz()

    let FIB = window.FIB,
      description
    if (FIB >= 2.67) {
      description = `Ten wynik może przemawiać za dużym włóknieniem wątroby. Wskazana konsultacja lekarska i ocena stanu wątroby na podstawie badań laboratoryjnych i USG, a w przypadku nieprawidłowości tych badań, konsultacja specjalistyczna u hepatologa.`
    } else if (FIB < 1.3) {
      description = `Taki wynik testu jest związany z bardzo małym ryzykiem zaawansowanej choroby wątroby.  W przypadku współistnienia czynników ryzyka: nadwaga, otyłość, cukrzyca regularne spożywanie alkoholu, zakażenia wirusami zapalenia wątroby: HBV lub HCV,  zaleca się kontrolę lekarską i powtarzanie badań w  odstępach 1-2 lat.`
    } else {
      description = `Taki wynik nie pozwala na jednoznaczne wykluczenie lub rozpoznanie zaawansowanego włóknienia wątroby. W celu dokładniejszej oceny stanu wątroby wskazana jest kontrola lekarska i rozszerzenie zakresu badań krwi, USG jamy brzusznej, elastografia.`
    }

    return [FIB.toFixed(2), description]
  }

  const [scoreAPRI, setScoreAPRI] = useState([0, null])
  const getScoreAPRI = (ast, ncm2, plt) => {
    window.AST = parseInt(ast, 10)
    window.NAST = parseInt(ncm2, 10)
    window.PLT = parseInt(plt, 10)
    window.pisz()

    let APRI = window.APRI,
      description
    if (APRI >= 2) {
      description = `Ten wynik może przemawiać za marskością wątroby. Wskazana konsultacja lekarska i ocena stanu wątroby w dalszych  badaniach laboratoryjnych i USG jamy brzusznej oraz  konsultacja specjalistyczna u hepatologa.`
    } else if (APRI >= 1.5 && APRI < 2) {
      description = `Ten wynik testu uzasadnia przyjrzenie się stanowi wątroby i sprawdzenie czy na pewno wszystko jest w porządku. Wskazana konsultacja lekarska i ocena stanu wątroby w dalszych  badaniach laboratoryjnych i USG jamy brzusznej.`
    } else if (APRI >= 0.5 && APRI < 1.5) {
      description = `Taki wynik testu nie pozwala na ocenę stanu Twojej wątroby.  W tym przedziale zazwyczaj znajdują się osoby ze zdrową wątrobą, chociaż mogą tu również trafić osoby z zaawansowanym włóknieniem wątroby ale bez wyraźnych odchyleń w badaniach laboratoryjnych. Jeśli istnieje podejrzenie przewlekłej choroby wątroby, szczególnie w przypadku współistnienia czynników ryzyka - nadwaga, otyłość, cukrzyca, , regularne spożywanie alkoholu, zakażenie wirusami zapalenia wątroby HBV lub HCV,  wskazana jest konsultacja hepatologiczna.`
    } else {
      description = `Twój wynik wskazuje na prawidłowy stan wątroby. Pamiętaj jednak, że jest to tylko wynik orientacyjny i zawsze warto zasięgnąć opinii lekarskiej.`
    }
    return [APRI.toFixed(2), description]
  }

  const firebase = useContext(FirebaseContext)
  const updateDocData = (values) => {
    if (!props.docId) return

    firebase
      .firestore()
      .collection('tests')
      .doc(props.docId)
      .update(values)
  }

  const StageSchema = Yup.object().shape({
    age: Yup.number()
      .min(1)
      .max(130)
      .required('Required'),
    weight: Yup.number()
      .min(1)
      .max(300)
      .required('Required'),
    height: Yup.number()
      .min(1)
      .max(230)
      .required('Required'),
  })

  const commentsInfo = () => {
    return (
      <StageColCalculation className="stageColCalculation">
        <StageCalculationWrapper>
          <StageCalculationListDesc>
            <li>
              <p>
                Badanie ALT nazywane jest także ALAT lub GPT. Badanie ALT
                wykonuje się z&nbsp;krwi. Jego celem jest określenie aktywności
                aminotransferazy alaninowej, który jest enzymem wątrobowym.
              </p>
            </li>
            <li>
              <p>
                Badanie AST to&nbsp;jedna z&nbsp;tzw. prób wątrobowych czyli
                enzymów znajdujących się głównie w&nbsp;wątrobie
                i&nbsp;mięśniach. Prawidłowo powinny występować wewnątrz
                komórek, dlatego wzrost ich aktywności we&nbsp;krwi wskazuje
                na&nbsp;uszkodzenie komórek, (np. wątrobowych). Oznaczenie
                aktywności AST wykonuje się w&nbsp;celu diagnostyki
                i&nbsp;monitorowania chorób wątroby.
              </p>
            </li>
            <li>
              <p>
                Płytki krwi (PLT) - odgrywają kluczową rolę w&nbsp;procesie
                krzepnięcia. Ich poziom określa się w&nbsp;ramach badania
                morfologicznego krwi.
              </p>
            </li>
            <li>
              <p>
                FIB-4 - Pozwala ocenić czy należy podejrzewać włóknienie
                wątroby, oraz ukierunkować dalszą diagnostykę pacjenta
                z&nbsp;wykorzystaniem badań obrazowych (USG lub/i elastografii).
              </p>
            </li>
            <li>
              <p>
                APRI - Wzrost wartości tego parametru koreluje
                z&nbsp;zaawansowaniem włóknienia w&nbsp;obrębie wątroby.
                Zastosowanie APRI zależy od&nbsp;przyjętego progu jego wartości.
              </p>
            </li>
          </StageCalculationListDesc>
        </StageCalculationWrapper>
      </StageColCalculation>
    )
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={StageSchema}
      initialValues={props.values}
      validate={(values) => {
        const errors = {}

        if (values.age && values.ast && values.alt && values.plt) {
          setScoreFIB4(
            getScoreFIB4(values.age, values.ast, values.alt, values.plt)
          )
        }

        if (values.ast && values.ncm2 && values.plt) {
          setScoreAPRI(getScoreAPRI(values.ast, values.ncm2, values.plt))
        }

        updateDocData(values)

        return errors
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <StageContainer>
            {props.header()}

            <StageWrapper>
              <StageCol lg={8} stage={props.stage}>
                <StageContentWrapper>
                  <StageCalculationFormGroupWrapper>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader>Twój wiek</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="age"
                        id="age"
                        placeholder="Podaj swój wiek"
                        label="lat"
                        value={values.age}
                        error={errors.age}
                        onChange={(e) => {
                          handleChange(e)

                          let value = e.target.value
                          if (value > 130) {
                            setFieldValue('age', 130)
                            return
                          }
                          setFieldValue(value)
                        }}
                        onBlur={handleBlur}
                      />
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader>Twój wzrost</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="height"
                        id="height"
                        placeholder="Podaj wzrost"
                        label="cm"
                        value={values.height}
                        error={errors.height}
                        onChange={(e) => {
                          handleChange(e)

                          let value = e.target.value
                          if (value > 230) {
                            setFieldValue('height', 230)
                            return
                          }
                          setFieldValue(value)
                        }}
                        onBlur={handleBlur}
                      />
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader>Twoja masa ciała</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="weight"
                        id="weight"
                        placeholder="Podaj wagę"
                        label="kg"
                        value={values.weight}
                        error={errors.weight}
                        onChange={(e) => {
                          handleChange(e)

                          let value = e.target.value
                          if (value > 300) {
                            setFieldValue('weight', 300)
                            return
                          }
                          setFieldValue(value)
                        }}
                        onBlur={handleBlur}
                      />
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader isTitleTwoLines={true}>ALT</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="alt"
                        id="alt"
                        placeholder="Wpisz liczbę"
                        label="U/I"
                        value={values.alt}
                        error={errors.alt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {parseInt(values.alt, 10) > 200 && (
                        <FormTextHelper>
                          Czy jesteś pewien wartości?
                        </FormTextHelper>
                      )}
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader isTitleTwoLines={true}>AST</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="ast"
                        id="ast"
                        placeholder="Wpisz liczbę"
                        label="U/I"
                        value={values.ast}
                        error={errors.ast}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {parseInt(values.ast, 10) > 200 && (
                        <FormTextHelper>
                          Czy jesteś pewien wartości?
                        </FormTextHelper>
                      )}
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInput stage={props.stage}>
                      <FormHeader isTitleTwoLines={true}>
                        górna granica normy dla AST
                      </FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="ncm2"
                        id="ncm2"
                        placeholder="Wpisz liczbę"
                        label="U/I"
                        value={values.ncm2}
                        error={errors.ncm2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {parseInt(values.ncm2, 10) > 55 && (
                        <FormTextHelper>
                          Czy jesteś pewien wartości?
                        </FormTextHelper>
                      )}
                    </FormItemLacationWrapperInput>
                    <FormItemLacationWrapperInputLast stage={props.stage}>
                      <FormHeader>płytki krwii (PLT)</FormHeader>
                      <FormCustomCalculationInput
                        type="number"
                        name="plt"
                        id="plt"
                        placeholder="Wpisz liczbę"
                        label="tys/µl"
                        value={values.plt}
                        error={errors.plt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormItemLacationWrapperInputLast>
                  </StageCalculationFormGroupWrapper>

                  <StageFormDescWrapper>
                    {(scoreFIB4 || scoreAPRI) && (
                      <p>
                        <big>
                          <strong>Twój wynik</strong>
                        </big>
                      </p>
                    )}

                    {scoreFIB4[1] && (
                      <p>
                        <big>
                          <strong>FIB-4 = {scoreFIB4[0]}</strong>
                        </big>{' '}
                        <br /> {scoreFIB4[1]}
                      </p>
                    )}

                    {scoreAPRI[1] && (
                      <p>
                        <big>
                          <strong>APRI = {scoreAPRI[0]}</strong>
                        </big>
                        <br /> {scoreAPRI[1]}
                      </p>
                    )}

                    <PdfRenderButton
                      values={{
                        apri: scoreAPRI,
                        fib4: scoreFIB4,
                        ...props.values,
                      }}
                    >
                      <span>Ściągnij podsumowanie testu</span>
                    </PdfRenderButton>
                  </StageFormDescWrapper>

                  <StageFormDescWrapper>
                    <NotesStage5 />
                  </StageFormDescWrapper>
                </StageContentWrapper>
              </StageCol>
              <PollStageColHeaderCalculationMobile xs={12}>
                <FormHeaderCalculation>
                  Informacje dodatkowe
                </FormHeaderCalculation>
              </PollStageColHeaderCalculationMobile>
              {commentsInfo()}
              <CalculatorWrapper
                dangerouslySetInnerHTML={{ __html: CalculatorHTML }}
              />
            </StageWrapper>
          </StageContainer>
        </Form>
      )}
    </Formik>
  )
}

export default StageHeader
