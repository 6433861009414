import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { FormGroup, FormText } from 'reactstrap'

import radioIcon from '../../images/radio_icon.svg'
import checkBoxIcon from '../../images/checkbox_icon.svg'
import checkBoxIconNegative from '../../images/checkbox_icon_negative.svg'

import genderFemaleCheckedIcon from '../../images/female_icon_checked.svg'
import genderFemaleUnCheckedIcon from '../../images/female_icon_unchecked.svg'
import genderMaleCheckedIcon from '../../images/male_icon_checked.svg'
import genderMaleUnCheckedIcon from '../../images/male_icon_unchecked.svg'

export const FormGroupWrapper = styled(FormGroup)`
  ${(props) =>
    props.ready === 'false' &&
    `color: ${props.theme.textColorWhite}!important;`};
  margin-bottom: 50px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-bottom: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
  .custom-radio,
  .custom-checkbox {
    ${(props) =>
      props.ready === 'false' &&
      `color: ${props.theme.textColorWhite}!important;`}
  }
`

export const FormHeader = styled.h3`
  font-size: 29px;
  font-weight: 700;
  @media (min-width: 1600px) {
    ${(props) =>
      props.isTitleTwoLines &&
      `
      min-height: 68px;
    `}
  }
  @media (min-width: 1200px) {
    ${(props) =>
      props.isTitleTwoLines &&
      `
      display: flex;
      align-items: flex-end;
    `}
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
    ${(props) =>
      props.isTitleTwoLines &&
      `
      display: flex;
      align-items: flex-end;
      min-height: 56px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
  sup {
    top: -1em;
    font-size: 12px;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 12px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 12px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 11px;
    }
    @media (max-width: 575px) {
      font-size: 10px;
    }
  }
`

export const FormHeaderCalculation = styled(FormHeader)`
  color: ${(props) => props.theme.textColorWhite};
  margin-bottom: 15px;
  @media (max-width: 575px) {
    display: none;
  }
`

export const FormHint = styled.p`
  font-size: 19px;
  font-style: italic;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
`

const FormCustomBtnWrapper = styled.div`
  /* min-height: 48px; */
  color: ${(props) => props.theme.colorOrangeRed};
  font-size: 22px;
  font-weight: 700;
  padding-left: 60px;
  margin-left: 10px;
  margin-bottom: 10px;  
  ${(props) => props.isone && `margin-top: 20px;`}
  ${(props) =>
    props.answer !== null
      ? props.answer === true
        ? `background-color: #d5e291;`
        : `background-color: #f9bcad;`
      : ''}
  ${(props) =>
    props.quizWithoutBG === true &&
    `
    background-color: transparent;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
    padding-left: 55px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding-left: 50px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    padding-left: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    padding-left: 35px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    padding-left: 35px;
  }
`

const FormField = styled(Field)`
  .custom-control-label {
    &:before,
    &:after {
      /* ${(props) => props.type === 'radio' && `border-radius: 50%!important;`}
      ${(props) =>
        props.type === 'checkbox' && `border-radius: 5px!important;`} */
    }
  }
  &:checked ~ .custom-control-label:before {
    background-color: ${(props) => props.theme.colorWhite};
    border-color: transparent;
    box-shadow: none;
  }
  &:checked ~ .custom-control-label:after {
    ${(props) =>
      props.type === 'radio' &&
      `background-image: url(${radioIcon})!important; background-size: 60%;`}
    ${(props) =>
      props.type === 'checkbox' &&
      `background-image: url(${checkBoxIcon})!important; background-size: 70%;`}
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none;
  }
  &:disabled ~ .custom-control-label {
    color: ${(props) => props.theme.colorWhite};
    ${(props) =>
      props.quizWithoutBG === true &&
      `
      color: ${props.theme.colorOrangeRed};
    `}    
    ${(props) =>
      props.isQuiz === true &&
      `
      color: ${props.theme.colorOrangeRed};
      font-weight: 400;
    `}
    &:before,
    &:after {
      ${(props) => props.type === 'radio' && `border-radius: 50%!important;`}
      ${(props) => props.type === 'checkbox' && `border-radius: 5px!important;`}
    }
    &:before {
      background-color: ${(props) => props.theme.colorWhite};
    }
    &:after {
      background-image: none !important;
      background-color: ${(props) => props.theme.colorWhite}!important;
      /* background: ${(props) => props.theme.colorWhite}!important; */
    }
  }
  &:disabled:checked ~ .custom-control-label {
    &:after {
      ${(props) =>
        props.type === 'radio' &&
        `background-image: url(${radioIcon})!important; background-size: 60%;`}
      ${(props) =>
        props.type === 'checkbox' &&
        `background-image: url(${checkBoxIcon})!important; background-size: 70%;`}
      ${(props) =>
        props.type === 'checkbox' &&
        props.isQuiz &&
        `background-image: url(${checkBoxIconNegative})!important; background-size: 65%;`}
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: none;
    }
    &:before {
      background-color: ${(props) => props.theme.colorWhite};
      border-color: transparent;
      box-shadow: none;
    }
  }
`

const FormCustomBtnLabel = styled.label`
  cursor: pointer;
  &:before,
  &:after {
    display: inline-block;
    width: 32px;
    height: 32px;
    /* top: 3px; */
    top: 50%;
    left: -50px;
    padding-top: 0;
    margin-top: -16px;
    border-color: transparent;
    box-shadow: none;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 26px;
      height: 26px;
      /* top: 0; */
      left: -45px;
      margin-top: -13px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 26px;
      height: 26px;
      /* top: 0; */
      left: -45px;
      margin-top: -13px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 26px;
      height: 26px;
      /* top: 0; */
      left: -45px;
      margin-top: -13px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 20px;
      height: 20px;
      /* top: 0; */
      left: -30px;
      margin-top: -10px;
    }
    @media (max-width: 575px) {
      width: 20px;
      height: 20px;
      /* top: 0; */
      left: -30px;
      margin-top: -10px;
    }
  }
`

const FormCustomGenderBtnWrapper = styled.div`
  display: inline-block;
  padding-left: 0;
  margin-left: 10px;
  &:last-of-type {
    margin-left: 17px;
    @media (max-width: 575px) {
      margin-top: 15px;
      margin-left: 10px;
    }
  }
  .custom-control-label {
    &:before,
    &:after {
      /* ${(props) => props.type === 'radio' && `border-radius: 50%!important;`}
      ${(props) =>
        props.type === 'checkbox' && `border-radius: 5px!important;`} */
    }
  }
`

const FormGenderField = styled(Field)`
  &:checked ~ .custom-control-label:before {
    content: none;
    /* background-color: ${(props) => props.theme.colorWhite};
    border-color: transparent;
    box-shadow: none; */
  }
  &:checked ~ .custom-control-label {
    background-color: ${(props) => props.theme.colorOrangeDark};
    box-shadow: 0px 20px 33px 0px rgba(237, 186, 150, 0.63);

    &:after {
      /* background-image: url(${genderFemaleCheckedIcon})!important; */
    ${(props) =>
      props.gender === 'female' &&
      `background-image: url(${genderFemaleCheckedIcon})!important;`}
    ${(props) =>
      props.gender === 'male' &&
      `background-image: url(${genderMaleCheckedIcon})!important;`}
    }
    /* ${(props) =>
      props.type === 'radio' &&
      `background-image: url(${radioIcon})!important; background-size: 60%;`}
    ${(props) =>
      props.type === 'checkbox' &&
      `background-image: url(${checkBoxIcon})!important; background-size: 70%;`}
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none; */
  }
  &:disabled ~ .custom-control-label {
    /* color: ${(props) => props.theme.colorWhite};
    &:before,
    &:after {
      ${(props) => props.type === 'radio' && `border-radius: 50%!important;`}
      ${(props) => props.type === 'checkbox' && `border-radius: 5px!important;`}
    }
    &:before {
      background-color: ${(props) => props.theme.colorWhite};
    }
    &:after {
      background-image: none !important;
      background-color: ${(props) => props.theme.colorWhite}!important;
      background: ${(props) => props.theme.colorWhite}!important;
    } */
  }
`

const FormCustomGenderBtnLabel = styled.label`
  background-color: #e6bfa2;
  display: inline-block;
  min-width: 305px;
  color: ${(props) => props.theme.textColorWhite};
  font-size: 29px;
  font-weight: 700;
  padding-top: 20px;
  padding-left: 92px;
  padding-right: 72px;
  padding-bottom: 20px;
  border-radius: 10px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    min-width: 250px;
    font-size: 24px;
    padding-top: 15px;
    padding-left: 75px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    min-width: 210px;
    font-size: 22px;
    padding-top: 12px;
    padding-left: 70px;
    padding-right: 15px;
    padding-bottom: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    min-width: 210px;
    font-size: 22px;
    padding-top: 12px;
    padding-left: 70px;
    padding-right: 15px;
    padding-bottom: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-width: 210px;
    font-size: 20px;
    padding-top: 12px;
    padding-left: 70px;
    padding-right: 15px;
    padding-bottom: 12px;
    border-radius: 6px;
  }
  @media (max-width: 575px) {
    min-width: 230px;
    /* width: 100%; */
    font-size: 18px;
    padding-top: 12px;
    padding-left: 70px;
    padding-right: 15px;
    padding-bottom: 12px;
    border-radius: 6px;
  }
  cursor: pointer;
  &:before {
    content: none;
  }
  &:after {
    width: 86px;
    height: auto;
    top: 0;
    left: 0;
    bottom: 0;
    ${(props) =>
      props.gender === 'female' &&
      `background-image: url(${genderFemaleUnCheckedIcon});`}
    ${(props) =>
      props.gender === 'male' &&
      `background-image: url(${genderMaleUnCheckedIcon});`}
    /* background-image: url(${genderFemaleUnCheckedIcon}); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 75px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 70px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 70px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 70px;
    }
    @media (max-width: 575px) {
      width: 70px;
    }
  }
`

const FormInput = styled(Field)`
  height: 62px;
  font-size: 24px;
  font-weight: 300;
  padding-left: 25px;
  margin-top: 20px;
  border-radius: 13px;
  border: 2px solid transparent;
  @media (min-width: 1200px) and (max-width: 1599px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 46px;
    font-size: 18px;
    padding-left: 20px;
    margin-top: 15px;
    border-radius: 10px;
  }
  @media (max-width: 575px) {
    height: 40px;
    font-size: 15px;
    padding-left: 20px;
    margin-top: 15px;
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    border: 2px solid transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colorWhite};
    & + .col-form-label {
      color: ${(props) => props.theme.colorWhite};
    }
  }
`

const FormInputWrapper = styled.div``

const FormInputCalculationWrapper = styled.div``

const FormInputCalculation = styled(FormInput)`
  display: inline-block;
  max-width: 240px;
  ${(props) => props.stage === 3 && `max-width: 105px;`}
  ${(props) =>
    props.error === 'not_number' &&
    `border-color: ${props.theme.colorOrangeRed};`}
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 205px;
    ${(props) => props.stage === 3 && `max-width: 95px`}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 200px;
    ${(props) => props.stage === 3 && `max-width: 95px`}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 200px;
    ${(props) => props.stage === 3 && `max-width: 95px`}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 200px;
    ${(props) => props.stage === 3 && `max-width: 90px`}
  }
  @media (max-width: 575px) {
    max-width: 200px;
    ${(props) => props.stage === 3 && `max-width: 85px`}
  }
  &:hover,
  &:focus {
    ${(props) =>
      props.error === 'not_number' &&
      `border-color: ${props.theme.colorOrangeRed}!important;`}
  }
`

const FormInputCalculationLabel = styled.label`
  display: inline-block;
  color: ${(props) => props.theme.colorOrangeRed};
  font-size: 18px;
  font-weight: 700;
  padding-left: 20px;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 14px;
    padding-left: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 16px;
    padding-left: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
    padding-left: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
    padding-left: 12px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    padding-left: 12px;
  }
  .multiplier {
    display: inline-block;
    position: relative;
    left: -7px;
    color: ${(props) => props.theme.colorBlack};
    @media (min-width: 768px) and (max-width: 991px) {
      left: -6px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      left: -5px;
    }
    @media (max-width: 575px) {
      left: 0;
      padding-right: 3px;
    }
  }
`

const FormSelectWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -20px;
    background-color: ${(props) => props.theme.colorWhite};
    z-index: 10;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 25px;
    display: block;
    width: 0;
    height: 0;
    margin-top: -4px;
    border-top: 9px solid ${(props) => props.theme.colorOrangeRed};
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    z-index: 20;
    /* ${(props) =>
      props.dataOpen === true ? 'border-bottom' : 'border-top'}: 9px solid
      ${(props) => props.theme.colorOrangeRed};   */
    @media (min-width: 1200px) and (max-width: 1599px) {
      margin-top: -3px;
      border-top: 7px solid ${(props) => props.theme.colorOrangeRed};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: -3px;
      border-top: 7px solid ${(props) => props.theme.colorOrangeRed};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: -3px;
      border-top: 7px solid ${(props) => props.theme.colorOrangeRed};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: -3px;
      border-top: 7px solid ${(props) => props.theme.colorOrangeRed};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
    @media (max-width: 575px) {
      margin-top: -3px;
      border-top: 7px solid ${(props) => props.theme.colorOrangeRed};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
`

const FormSelect = styled(Field)`
  height: 62px;
  font-size: 24px;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 50px;
  border-radius: 13px;
  border: none;
  box-shadow: 0px 20px 33px 0px rgba(225, 208, 196, 0.63);
  appearance: none;
  @media (min-width: 1200px) and (max-width: 1599px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 52px;
    font-size: 20px;
    padding-left: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 46px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 10px;
  }
  @media (max-width: 575px) {
    height: 40px;
    font-size: 15px;
    padding-left: 20px;
    border-radius: 10px;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colorWhite};
  }
`

export const FormDescWrapper = styled.div`
  font-size: 22px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  .testCodeWrapper {
    text-align: center;
    .testCode {
      display: inline-block;
      background-color: ${(props) => props.theme.colorOrangeRed};
      color: ${(props) => props.theme.colorWhite};
      font-size: larger;
      padding-top: 15px;
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 15px;
      border-radius: 12px;
      transition: ease 0.2s all;
      @media (max-width: 333px) {
        background-size: 24px auto;
        font-size: 1em;
        padding-right: 65px !important;
      }
      @media (max-width: 575px) {
        padding-top: 8px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 8px;
        border-radius: 8px;
      }
      &.copied {
        background-color: rgb(201, 88, 33);
      }
    }
  }
  p {
    font-weight: 700;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      &:before {
        content: '-';
        position: relative;
        left: -5px;
      }
    }
  }
`

export const FormItemLacationWrapper = styled.div`
  @media (min-width: 1200px) {
    ${(props) =>
      props.stage === 5
        ? `
      display: block;
      flex: 0 0 33.33%;
      max-width: 33.33%;
    `
        : `
      display: inline-block;
      width: 33.33%;
    `};
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .col-form-label {
    @media (min-width: 1600px) {
      font-size: 20px;
      padding-left: 5px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 14px;
      padding-left: 3px;
    }
  }
  input {
    @media (min-width: 1600px) {
      max-width: 246px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      max-width: 170px;
    }
  }
`

export const FormTextHelper = styled(FormText)`
  color: ${(props) => props.theme.colorOrangeRed};
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
  &.text-muted {
    color: ${(props) => props.theme.colorOrangeRed}!important;
  }
`

export const FormCustomBtnInput = (props) => {
  let isDisabled = false
  let answer = null
  let isQuiz = props.isQuiz || false

  if (props.disabled === 'false') {
    isDisabled = true
  }

  if (props.isQuiz) {
    isDisabled = true

    if (props.value && props.answer) {
      answer = true
    }
    if (props.value && !props.answer) {
      answer = false
    }
  }

  return (
    <FormCustomBtnWrapper
      className={`${props.type === 'radio' ? 'custom-radio' : ''}${
        props.type === 'checkbox' ? 'custom-checkbox' : ''
      } custom-control`}
      isone={props.isOne}
      answer={answer}
      quizWithoutBG={props.quizWithoutBG}
    >
      <FormField
        type={props.type}
        name={props.name}
        id={props.id}
        className="custom-control-input"
        value={props.value}
        disabled={isDisabled}
        isQuiz={isQuiz}
        quizWithoutBG={props.quizWithoutBG}
      />
      <FormCustomBtnLabel
        className="custom-control-label"
        htmlFor={props.id}
        type={props.type}
      >
        {props.label}
      </FormCustomBtnLabel>
    </FormCustomBtnWrapper>
  )
}

export const FormCustomGenderBtnInput = (props) => {
  let isDisabled = false

  if (props.disabled === 'false') {
    isDisabled = true
  }

  return (
    <FormCustomGenderBtnWrapper className="custom-radio custom-control">
      <FormGenderField
        type="radio"
        name={props.name}
        id={props.id}
        className="custom-control-input"
        value={props.value}
        disabled={isDisabled}
        gender={props.gender}
      />
      <FormCustomGenderBtnLabel
        className="custom-control-label"
        htmlFor={props.id}
        type={props.type}
        gender={props.gender}
      >
        {props.label}
      </FormCustomGenderBtnLabel>
    </FormCustomGenderBtnWrapper>
  )
}

export const FormCustomInput = (props) => {
  let isDisabled = false

  if (props.disabled === 'false') {
    isDisabled = true
  }

  return (
    <FormInputWrapper>
      <FormInput
        type={props.type}
        name={props.name}
        id={props.id}
        className="form-control"
        value={props.value}
        disabled={isDisabled}
        placeholder={props.placeholder}
      />
    </FormInputWrapper>
  )
}

export const FormCustomCalculationInput = (props) => {
  let isDisabled = false

  if (props.disabled === 'false') {
    isDisabled = true
  }

  return (
    <FormInputCalculationWrapper>
      <FormInputCalculation
        type={props.type}
        name={props.name}
        id={props.id}
        min={0}
        max={props.max ? props.max : null}
        className="form-control"
        value={props.value}
        defaultValue={props.defaultValue ? props.defaultValue : null}
        disabled={isDisabled}
        placeholder={props.placeholder}
        error={props.error}
        onChange={props.onChange}
        onBlur={props.onBlur}
        stage={props.stage}
      />
      <FormInputCalculationLabel className="col-form-label" htmlFor={props.id}>
        {props.label}
      </FormInputCalculationLabel>
    </FormInputCalculationWrapper>
  )
}

export const FormCustomSelect = (props) => {
  let isDisabled = false

  if (props.disabled === 'false') {
    isDisabled = true
  }

  return (
    <FormSelectWrapper>
      <FormSelect
        component="select"
        name={props.name}
        id={props.id}
        className="custom-select"
        disabled={isDisabled}
      >
        {props.children}
      </FormSelect>
    </FormSelectWrapper>
  )
}
