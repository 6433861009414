import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { MainContainer } from '../tags'

import progressBarImage from '../../images/progress_bar_bg.svg'

const ProgressBarWrapper = styled.div``

const ProgressBarContainer = styled(MainContainer)`
  position: relative;
  background-image: url(${progressBarImage});
  background-repeat: repeat-y;
  background-position-x: right;
  @media (max-width: 767px) {
    background-position-x: left;
  }
`

const ProgressBarBGLine = styled.div`
  position: relative;
  height: 35px;
  background: #e2d2d2;
  background: #e2d2d2;
  background: -moz-linear-gradient(
    top,
    #e2d2d2 1%,
    #ede3e3 39%,
    #f6f2f2 67%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    top,
    #e2d2d2 1%,
    #ede3e3 39%,
    #f6f2f2 67%,
    #ffffff 100%
  );
  background: linear-gradient(
    to bottom,
    #e2d2d2 1%,
    #ede3e3 39%,
    #f6f2f2 67%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2d2d2', endColorstr='#ffffff',GradientType=0 );
`

const ProgressBarRow = styled(Row)`
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const ProgressBarItemStageInnerWrapper = styled.span``

const ProgressBarItem = styled(Col)`
  position: relative;
  ${(props) => props.stage === props.current && `color: #2e2d2b;`}
  ${(props) =>
    props.stage < props.current && `color: ${props.theme.textColorOrangeRed};`}
  ${(props) =>
    props.stage > props.current && `color: rgba(255, 255, 255, 0.502);`}
  /* color: ${(props) => props.theme.textColorWhite};
  color: ${(props) => props.theme.textColorOrangeRed}; */
  /* color: ${(props) => props.theme.textColorBlack}; */
  font-size: 26px;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 16px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: 767px) {
    /* flex: 0 0 100%;
    max-width: 100%; */
    ${(props) =>
      props.stage <= props.current &&
      `
      color: ${props.theme.textColorWhite};
    `}
    ${(props) =>
      props.stage > props.current &&
      `
      color: #d6a74b;
    `}
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;    
    padding-bottom: 20px;
  }
  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 10px;
    left: 15px;
    right: 15px;
    bottom: 0;
    background-color: ${(props) =>
      props.stage > props.current
        ? props.theme.colorWhite
        : props.theme.colorOrangeRed};
    /* background-color: ${(props) =>
      false ? props.theme.colorWhite : props.theme.colorOrangeRed}; */
    border-radius: 24px;
    @media (min-width: 768px) and (max-width: 991px) {
      height: 6px;
    }
    @media (max-width: 767px) {
      top: 50%;
      bottom: inherit;
      ${(props) =>
        props.stage <= props.current &&
        `
      background-color: #d0522e;
      `}
      ${(props) =>
        props.stage > props.current &&
        `
      background-color: ${props.theme.colorWhite};
      `}
    }
    @media (min-width: 576px) and (max-width: 767px) {
      /* height: 6px; */
      right: -5px;
      margin-top: -5px;
    }
    @media (max-width: 575px) {
      height: 8px;
      left: 30px;
      right: -5px;
      margin-top: -4px;
    }
  }
  &:after {
      content: none;
      position: absolute;
      left: -12px;
      bottom: -6px;
      display: block;
      width: 24px;
      height: 24px;
      background-color: ${(props) =>
        false ? props.theme.colorWhite : props.theme.colorOrangeRed};
      border-radius: 12px;
      @media (min-width: 768px) and (max-width: 991px) {
        bottom: -4px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }
      /* @media (min-width: 576px) and (max-width: 767px) {
        bottom: -4px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
      } */
    }
  &:first-child {
    &:before {
      left: 0;
      @media (max-width: 767px) {
        left: 15px;
      }
      @media (max-width: 575px) {
        left: 30px;
      }
    }
    &:after {
      content: '';
      @media (max-width: 767px) {
        content: none;
      }
    }
  }
  &:last-child {
    &:before {
      @media (max-width: 767px) {
        content: none;
      }
    }
  }
  ${ProgressBarItemStageInnerWrapper} {
    ${(props) =>
      props.stage <= props.current &&
      `
      font-weight: 700;
    `}
  }
`

const ProgressBarItemStage = styled.span`
  font-weight: 300;
  @media (max-width: 767px) {
    position: relative;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #d0522e;
    ${(props) =>
      props.stage > props.current &&
      `
      background-color: #fff;
    `}
    border-radius: 50%;
  }
  @media (max-width: 575px) {
    width: 46px;
    height: 46px;
  }
  @media (max-width: 360px) {
    width: 38px;
    height: 38px;
  }
`

const ProgressBarItemStageZero = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`

const ProgressBarItemStageNumber = styled.span``

const ProgressBarItemStageDot = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`

const ProgressBarItemTitle = styled.p`
  font-weight: 700;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    /* display: inline-block; */
    display: none;
    padding-left: 5px;
  }
`

const ProgressBar = (props) => {
  const setPrevtStage = (stage) => {
    if (stage <= props.completedStage) {
      props.setPrevtStage(stage)
    }
  }
  return (
    <ProgressBarWrapper>
      <ProgressBarContainer>
        <ProgressBarRow>
          <ProgressBarItem
            stage={1}
            current={props.stage}
            onClick={() => setPrevtStage(1)}
          >
            <ProgressBarItemStage stage={1} current={props.stage}>
              <ProgressBarItemStageInnerWrapper>
                <ProgressBarItemStageZero>0</ProgressBarItemStageZero>
                <ProgressBarItemStageNumber>1</ProgressBarItemStageNumber>
                <ProgressBarItemStageDot>.</ProgressBarItemStageDot>
              </ProgressBarItemStageInnerWrapper>
            </ProgressBarItemStage>
            <ProgressBarItemTitle>Test wiedzy</ProgressBarItemTitle>
          </ProgressBarItem>
          <ProgressBarItem
            stage={2}
            current={props.stage}
            onClick={() => setPrevtStage(2)}
          >
            <ProgressBarItemStage stage={2} current={props.stage}>
              <ProgressBarItemStageInnerWrapper>
                <ProgressBarItemStageZero>0</ProgressBarItemStageZero>
                <ProgressBarItemStageNumber>2</ProgressBarItemStageNumber>
                <ProgressBarItemStageDot>.</ProgressBarItemStageDot>
              </ProgressBarItemStageInnerWrapper>
            </ProgressBarItemStage>
            <ProgressBarItemTitle>Metryczka</ProgressBarItemTitle>
          </ProgressBarItem>
          <ProgressBarItem
            stage={3}
            current={props.stage}
            onClick={() => setPrevtStage(3)}
          >
            <ProgressBarItemStage stage={3} current={props.stage}>
              <ProgressBarItemStageInnerWrapper>
                <ProgressBarItemStageZero>0</ProgressBarItemStageZero>
                <ProgressBarItemStageNumber>3</ProgressBarItemStageNumber>
                <ProgressBarItemStageDot>.</ProgressBarItemStageDot>
              </ProgressBarItemStageInnerWrapper>
            </ProgressBarItemStage>
            <ProgressBarItemTitle>Styl życia i objawy</ProgressBarItemTitle>
          </ProgressBarItem>
          <ProgressBarItem
            stage={4}
            current={props.stage}
            onClick={() => setPrevtStage(4)}
          >
            <ProgressBarItemStage stage={4} current={props.stage}>
              <ProgressBarItemStageInnerWrapper>
                <ProgressBarItemStageZero>0</ProgressBarItemStageZero>
                <ProgressBarItemStageNumber>4</ProgressBarItemStageNumber>
                <ProgressBarItemStageDot>.</ProgressBarItemStageDot>
              </ProgressBarItemStageInnerWrapper>
            </ProgressBarItemStage>
            <ProgressBarItemTitle>Podsumowanie</ProgressBarItemTitle>
          </ProgressBarItem>
          <ProgressBarItem
            stage={5}
            current={props.stage}
            onClick={() => setPrevtStage(5)}
          >
            <ProgressBarItemStage stage={5} current={props.stage}>
              <ProgressBarItemStageInnerWrapper>
                <ProgressBarItemStageZero>0</ProgressBarItemStageZero>
                <ProgressBarItemStageNumber>5</ProgressBarItemStageNumber>
                <ProgressBarItemStageDot>.</ProgressBarItemStageDot>
              </ProgressBarItemStageInnerWrapper>
            </ProgressBarItemStage>
            <ProgressBarItemTitle>Kalkulator ryzyka</ProgressBarItemTitle>
          </ProgressBarItem>
        </ProgressBarRow>
      </ProgressBarContainer>
      <ProgressBarBGLine className="container" />
    </ProgressBarWrapper>
  )
}

export default ProgressBar
