import React, { useState } from 'react'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { DownloadLink } from '../tags'

const ButtonDownloadLink = styled(DownloadLink)`
  margin-top: 40px;

  @media (max-width: 575px) {
    padding-top: 8px;
    padding-right: 50px;
    padding-bottom: 10px;
    margin-top: 30px;
  }
  @media (max-width: 340px) {
    padding-top: 5px;
    padding-right: 50px;
    padding-bottom: 10px;
  }
  &:after {
    width: 50px;
  }
  span {
    padding-left: 60px;
    padding-right: 60px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 18px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 16px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 16px;
      padding-left: 35px;
      padding-right: 35px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
      padding-left: 25px;
      padding-right: 25px;
    }
    @media (max-width: 340px) {
      font-size: 13px;
      padding-left: 18px;
      padding-right: 18px;
    }
`

export const PdfRenderLink = (props) => (
  <ButtonDownloadLink {...props}>{props.children}</ButtonDownloadLink>
)

export default ({ children, values }) => {
  const [isRendering, setIsRendering] = useState(false)
  const data = useStaticQuery(graphql`
    query Stage4PdfConfig {
      site {
        siteMetadata {
          pdfRenderUrl
        }
      }
    }
  `)
  const handlePdfRenderClick = (e) => {
    e.preventDefault()

    if (process.browser) {
      window.dataLayer.push({
        event: 'event',
        category: 'PDF Download',
      })
    }

    setIsRendering(true)
    axios
      .post(data.site.siteMetadata.pdfRenderUrl, values, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/pdf',
        })
        saveAs(blob, 'essentiale-ankieta.pdf')
        setIsRendering(false)
      })
      .catch((err) => {
        alert(
          'Przepraszamy. Wystąpił błąd. Prosimy zapisać unikalny kod testu i wrócić do tej strony później.'
        )
        setIsRendering(false)
      })
  }

  return (
    <ButtonDownloadLink
      href="#"
      disabled={isRendering}
      onClick={handlePdfRenderClick}
    >
      {!isRendering ? (
        children
      ) : (
        <>
          <span>Proszę czekać...</span>
        </>
      )}
    </ButtonDownloadLink>
  )
}
