import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFirebase } from 'gatsby-plugin-firebase'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { MainContainer } from '../tags'

import counterBG from '../../images/counter_bg.svg'

const CounterWrapper = styled.div``

const CounterContainer = styled(MainContainer)`
  position: relative;
  background-image: url(${counterBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 112% auto;
  @media (min-width: 1200px) and (max-width: 1599px) {
    background-size: 150% auto;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    background-size: 140% auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    background-size: 150% auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    background-size: 180% auto;
  }
  @media (max-width: 575px) {
    background-size: 240% auto;
  }
  @media (max-width: 340px) {
    background-size: 250% auto;
  }
`

const CounterRow = styled(Row)``

const CounterCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const CounterTitleNumber = styled.span`
  display: inline-block;
  font-size: 31px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 27px;
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
    padding-top: 1px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
  }
`

const CounterTitle = styled.p`
  color: ${(props) => props.theme.colorWhite};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 16px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 10px;
  }
  @media (max-width: 340px) {
    font-size: 8px;
  }
  ${CounterTitleNumber} {
    &:nth-child(odd) {
      background-color: rgb(97, 60, 61);
    }
  }
`

const CounterTitleText = styled.span`
  display: inline-block;
  padding-left: 20px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 16px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 14px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 10px;
  }
  @media (max-width: 575px) {
    padding-left: 10px;
  }
  @media (max-width: 340px) {
    padding-left: 6px;
  }
`

const Counter = (props) => {
  const splitCounter = (counter, position, quantity = 6) => {
    let counterArrayStr = counter.toString().split('')
    let templateArrayStr = []

    for (let i = 0; i < quantity; i++) {
      templateArrayStr.push('0')
    }

    counterArrayStr = counterArrayStr.reverse()

    for (let i = counterArrayStr.length - 1; i >= 0; i--) {
      templateArrayStr[quantity - 1 - i] = counterArrayStr[i]
    }

    return templateArrayStr[position]
  }

  const data = useStaticQuery(graphql`
    query CounterConfigQuery {
      site {
        siteMetadata {
          counterDocId
        }
      }
    }
  `)
  useFirebase((firebase) => {
    firebase
      .firestore()
      .collection('counters')
      .doc(data.site.siteMetadata.counterDocId)
      .onSnapshot((docSnapshot) => {
        if (!docSnapshot) return
        let number = docSnapshot.data()
        number = number['count']
        props.setCounterNumber(number)
      })
  }, [])

  return (
    <CounterWrapper>
      <CounterContainer>
        <CounterRow>
          <CounterCol>
            <CounterTitle>
              <CounterTitleNumber>
                {splitCounter(props.counter, 0, 6)}
              </CounterTitleNumber>
              <CounterTitleNumber>
                {splitCounter(props.counter, 1, 6)}
              </CounterTitleNumber>
              <CounterTitleNumber>
                {splitCounter(props.counter, 2, 6)}
              </CounterTitleNumber>
              <CounterTitleNumber>
                {splitCounter(props.counter, 3, 6)}
              </CounterTitleNumber>
              <CounterTitleNumber>
                {splitCounter(props.counter, 4, 6)}
              </CounterTitleNumber>
              <CounterTitleNumber>
                {splitCounter(props.counter, 5, 6)}
              </CounterTitleNumber>
              <CounterTitleText>
                OSÓB SPRAWDZIŁO STAN SWOJEJ WĄTROBY
              </CounterTitleText>
            </CounterTitle>
          </CounterCol>
        </CounterRow>
      </CounterContainer>
    </CounterWrapper>
  )
}

export default Counter
