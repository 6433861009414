import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Continue from './continue'
import StageComments from './stage-comments'
import {
  FormGroupWrapper,
  FormHeader,
  FormCustomGenderBtnInput,
  FormCustomCalculationInput,
  FormCustomSelect,
  FormItemLacationWrapper,
} from './form-tags'
import {
  PollStageWrapper,
  PollStageCol,
  PollStageContentWrapper,
  StageContainer,
} from '../tags'

import { checkReady } from '../../lib/functions'

const StageWrapper = styled(PollStageWrapper)``

const StageCol = styled(PollStageCol)``

const StageContentWrapper = styled(PollStageContentWrapper)``

const StageHeader = (props) => {
  const SignupSchema = Yup.object().shape({
    gender: Yup.string().required('Required'),
    age: Yup.number()
      .min(1)
      .max(130)
      .required('Required'),
    weight: Yup.number()
      .min(1)
      .max(300)
      .required('Required'),
    height: Yup.number()
      .min(1)
      .max(230)
      .required('Required'),
    province: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    education: Yup.string().required('Required'),
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.values}
      validationSchema={SignupSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        props.nextStage(values)
      }}
    >
      {({
        handleChange,
        handleBlur,
        submitForm,
        errors,
        touched,
        values,
        setFieldValue,
      }) => (
        <>
          <StageContainer>
            {props.header()}
            <StageWrapper>
              <StageCol lg={8} stage={props.stage}>
                <StageContentWrapper>
                  <Form>
                    <FormGroupWrapper>
                      <FormHeader>Twoja płeć</FormHeader>
                      <FormCustomGenderBtnInput
                        name="gender"
                        id="female"
                        value="kobieta"
                        gender="female"
                        label="kobieta"
                      />
                      <FormCustomGenderBtnInput
                        name="gender"
                        id="male"
                        value="mężczyzna"
                        gender="male"
                        label="mężczyzna"
                      />
                    </FormGroupWrapper>

                    <FormGroupWrapper ready={checkReady(values.gender)}>
                      <FormItemLacationWrapper>
                        <FormHeader>Twój wiek</FormHeader>
                        <FormCustomCalculationInput
                          type="number"
                          name="age"
                          id="age"
                          placeholder="Wpisz liczbę"
                          label="lat"
                          max="130"
                          value={values.age}
                          disabled={checkReady(values.gender)}
                          error={Boolean(touched.age && errors.age)}
                          onChange={(e) => {
                            handleChange(e)

                            let value = e.target.value
                            if (value > 130) {
                              setFieldValue('age', 130)
                              return
                            }
                            setFieldValue(value)
                          }}
                          onBlur={handleBlur}
                        />
                      </FormItemLacationWrapper>
                      <FormItemLacationWrapper>
                        <FormHeader>Twój wzrost</FormHeader>
                        <FormCustomCalculationInput
                          type="number"
                          name="height"
                          id="height"
                          placeholder="Wpisz swój wzrost"
                          label="cm"
                          max="230"
                          value={values.height}
                          disabled={checkReady(values.age)}
                          error={Boolean(touched.height && errors.height)}
                          onChange={(e) => {
                            handleChange(e)

                            let value = e.target.value
                            if (value > 230) {
                              setFieldValue('height', 230)
                              return
                            }
                            setFieldValue(value)
                          }}
                          onBlur={handleBlur}
                        />
                      </FormItemLacationWrapper>
                      <FormItemLacationWrapper>
                        <FormHeader>Twoja masa ciała</FormHeader>
                        <FormCustomCalculationInput
                          type="number"
                          name="weight"
                          id="weight"
                          placeholder="Wpisz masę ciała"
                          label="kg"
                          max="300"
                          value={values.weight}
                          disabled={checkReady(values.height)}
                          error={Boolean(touched.weight && errors.weight)}
                          onChange={(e) => {
                            handleChange(e)

                            let value = e.target.value
                            if (value > 300) {
                              setFieldValue('weight', 300)
                              return
                            }
                            setFieldValue(value)
                          }}
                          onBlur={handleBlur}
                        />
                      </FormItemLacationWrapper>
                    </FormGroupWrapper>

                    <FormGroupWrapper ready={checkReady(values.height)}>
                      <FormHeader>Miejsce zamieszkania</FormHeader>
                      <FormCustomSelect
                        name="province"
                        id="province"
                        disabled={checkReady(values.height)}
                      >
                        <option>Wybierz województwo, w którym mieszkasz</option>
                        <option value="dolnośląskie">dolnośląskie</option>
                        <option value="kujawsko-pomorskie">
                          kujawsko-pomorskie
                        </option>
                        <option value="lubelskie">lubelskie</option>
                        <option value="lubuskie">lubuskie</option>
                        <option value="łódzkie">łódzkie</option>
                        <option value="małopolskie">małopolskie</option>
                        <option value="mazowieckie">mazowieckie</option>
                        <option value="opolskie">opolskie</option>
                        <option value="podkarpackie">podkarpackie</option>
                        <option value="podlaskie">podlaskie</option>
                        <option value="pomorskie">pomorskie</option>
                        <option value="śląskie">śląskie</option>
                        <option value="świętokrzyskie">świętokrzyskie</option>
                        <option value="warmińsko-mazurskie">
                          warmińsko-mazurskie
                        </option>
                        <option value="wielkopolskie">wielkopolskie</option>
                        <option value="zachodniopomorskie">
                          zachodniopomorskie
                        </option>
                      </FormCustomSelect>
                      <FormCustomSelect
                        name="city"
                        id="city"
                        disabled={checkReady(values.province)}
                      >
                        <option>Miejscowość</option>
                        <option value="wieś">wieś</option>
                        <option value="miasto do 50 tys. mieszkańców">
                          miasto do 50 tys. mieszkańców
                        </option>
                        <option value="miasto 50-500 tys. mieszkańców">
                          miasto 50-500 tys. mieszkańców
                        </option>
                        <option value="miasto powyżej 500 tys. mieszkańców">
                          miasto powyżej 500 tys. mieszkańców
                        </option>
                      </FormCustomSelect>
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.city)}>
                      <FormHeader>Wykształcenie</FormHeader>
                      <FormCustomSelect
                        name="education"
                        id="education"
                        disabled={checkReady(values.city)}
                      >
                        <option>Wybierz swoje wykształcenie</option>
                        <option value="podstawowe">podstawowe</option>
                        <option value="średnie">średnie</option>
                        <option value="wyższe">wyższe</option>
                      </FormCustomSelect>
                    </FormGroupWrapper>
                  </Form>
                </StageContentWrapper>
              </StageCol>
              <StageComments values={values} />
            </StageWrapper>
          </StageContainer>
          <Continue
            stage={2}
            handleClick={submitForm}
            errorText={
              Object.keys(errors).length > 0 && (
                <p>
                  <strong>UWAGA!</strong> Aby przejść dalej prosimy odpowiedzieć
                  na wszystkie powyższe pytania.
                </p>
              )
            }
          />
        </>
      )}
    </Formik>
  )
}

export default StageHeader
