import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from 'reactstrap'

import { MainContainer } from '../tags'

const ContinueWrapper = styled.div`
  margin-top: 80px;
  ${(props) => props.isQuiz && `margin-top: 0;`}
  ${(props) =>
    props.stage === 4 &&
    `margin-top: 40px!important;`}
  @media (max-width: 575px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-bottom: 0!important;
      margin-top: 15px!important;
    `}
  }
`

const ContinueContainer = styled(MainContainer)`
  ${(props) =>
    props.stage === 4 &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.stage === 4 &&
      `
    padding-left: 15px;
    padding-right: 15px;
  `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 15px;
      padding-right: 15px;
    `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 15px;
      padding-right: 15px;
    `}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 15px;
      padding-right: 15px;
    `}
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 15px;
      padding-right: 15px;
    `}
  }
`

const ContinueContent = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 575px) {
    ${(props) =>
      props.stage === 4 &&
      `
      padding-bottom: 0!important;
    `}
  }
`

const ContinueNextStage = styled.p`
  font-size: 29px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    ${(props) =>
      props.stage === 1 &&
      `
      margin-top: 30px;
    `}
  }
`

const ContinueNextStageName = styled.span`
  font-weight: 700;
`

const ContinueButtonWrapper = styled.div`
  margin-top: 10px;
  @media (min-width: 992px) {
    ${(props) =>
      props.stage === 4 &&
      `
      text-align: center;
    `}
  }
`

const ContinueButton = styled(Button)`
  background-color: rgb(201, 88, 33);
  font-size: 29px;
  font-weight: 700;
  padding-top: 0.48rem;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 15px !important;
  border: 8px solid rgb(247, 141, 64);
  border-radius: 20px;
  box-shadow: 0px 20px 33px 0px rgba(237, 186, 150, 0.63);
  transition: ease 0.2s all;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 40px;
      padding-right: 40px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
    ${(props) =>
      props.stage === 4 &&
      `
      font-size: 20px;
      padding-left: 30px;
      padding-right: 30px;
    `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    ${(props) =>
      props.stage === 4 &&
      `
      padding-left: 30px;
      padding-right: 30px;
    `}
  }
  @media (max-width: 360px) {
    ${(props) =>
      props.stage === 1 &&
      `
      padding-left: 20px;
      padding-right: 20px;
    `}
  }
  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: rgb(247, 141, 64) !important;
    border: 8px solid rgb(247, 141, 64) !important;
    box-shadow: 0px 23px 36px 0px rgba(237, 186, 150, 0.85) !important;
  }
  &:disabled {
    opacity: 1;
    box-shadow: 0px 20px 33px 0px rgba(237, 186, 150, 0.63);
  }
`

const ContinueButtonEndLabel = styled.span`
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  padding-left: 20px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    display: block;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 105px;
    padding-bottom: 10px !important;
  }
`

const ContinueButtonEnd = styled(Link)`
  display: inline-block;
  color: ${(props) => props.theme.colorOrangeRed};
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
  padding-left: 6px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    display: block;
    font-size: 18px;
    padding-left: 62px;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorOrangeRed};
    text-decoration: underline;
  }
`

const Continue = (props) => {
  let nextStage = props.stage ? props.stage : 1
  let nextStageTitle = ''

  switch (nextStage) {
    case 1:
      nextStageTitle = !props.isQuiz ? 'Zobacz swój wynik' : 'Metryczka'
      break
    case 2:
      nextStageTitle = 'Styl życia i samopoczucie'
      break
    case 3:
      nextStageTitle = 'Podsumowanie wyników'
      break
    case 4:
      nextStageTitle = 'Kalkulator ryzyka'
      break
    default:
      nextStageTitle = 'Metryczka'
      break
  }

  return (
    <ContinueWrapper stage={props.stage} isQuiz={props.isQuiz}>
      <ContinueContainer stage={props.stage}>
        <ContinueContent stage={props.stage}>
          {props.stage !== 4 && (
            <ContinueNextStage stage={props.stage}>
              <span>Następny etap: </span>
              <ContinueNextStageName>{nextStageTitle}</ContinueNextStageName>
            </ContinueNextStage>
          )}

          {props.errorText}

          <ContinueButtonWrapper stage={props.stage}>
            {props.handleClick && (
              <ContinueButton
                stage={props.stage}
                className="continueButton"
                onClick={props.handleClick}
              >
                {props.stage === 1 && !props.isQuiz && 'Kontynuuj'}
                {props.isQuiz && 'Kontynuuj'}
                {props.stage !== 4 && props.stage !== 1 && 'Kontynuuj'}
                {props.stage === 4 && 'Kalkulator ryzyka'}
              </ContinueButton>
            )}

            {props.stage === 4 && false && (
              <>
                <ContinueButtonEndLabel>lub</ContinueButtonEndLabel>{' '}
                <ContinueButtonEnd to="/">Zakończ test</ContinueButtonEnd>
              </>
            )}
          </ContinueButtonWrapper>
        </ContinueContent>
      </ContinueContainer>
    </ContinueWrapper>
  )
}

export default Continue
