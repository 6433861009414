import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { globalHistory } from '@reach/router'
import { scroller } from 'react-scroll'
import queryString from 'query-string'

import Counter from './counter'
import ProgressBar from './progress-bar'
import ProgressTitleStage from './progress-title-stage'
import Stages from './stages'

const PollMainWrapper = styled.section`
  margin-bottom: 60px;
  @media (max-width: 575px) {
    overflow: hidden;
  }
`

export default (props) => {
  const [currentStage, setCurrentStage] = useState(1)
  const [completedStage, setCompletedStage] = useState(1)
  const [docId, setDocId] = useState()
  const [values, setValues] = useState({})
  const [isQuiz, setQuiz] = useState(false)
  const [answeredQuestions, setAnsweredQuestions] = useState(0)
  const [counter, setCounter] = useState(0)
  const [percentAnswers, setPercentAnswers] = useState(0)

  const addValues = (newValues) => setValues({ ...values, ...newValues })
  const setNextStage = () => {
    setCurrentStage(currentStage + 1)
  }
  const nextStage = (newValues) => {
    addValues(newValues)
    setNextStage()
    setCompletedStage(completedStage + 1)

    scroller.scrollTo('stageTitleHeader', {
      duration: 1000,
      offset: -50,
      smooth: true,
    })

    if (process.browser && 'parentIFrame' in window) {
      window.parentIFrame.scrollTo(0, 0);
    }
  }

  const setPrevtStage = (stage) => {
    if (currentStage >= 2 && completedStage === currentStage) {
      setCurrentStage(stage)
      setCompletedStage(stage)
    }
  }

  const setQuizAnsvers = (toogleQuiz) => {
    if (currentStage === 1) {
      setQuiz(toogleQuiz)
    }
  }

  const setRightAnsweredQuestions = (quantity) => {
    setAnsweredQuestions(quantity)
  }

  const setCounterNumber = (number) => {
    setCounter(number)
  }

  const setQuizPercentAnswers = (percent) => {
    setPercentAnswers(percent)
  }

  useEffect(() => {
    if (globalHistory.location.search) {
      let pollID = queryString.parse(globalHistory.location.search, {
        arrayFormat: 'comma',
      })

      if (typeof pollID['id'] !== 'undefined') {
        setCurrentStage(4)
        setCompletedStage(4)
      }
    }
  }, [])

  return (
    <PollMainWrapper>
      <Counter counter={counter} setCounterNumber={setCounterNumber} />
      <ProgressBar
        stage={currentStage}
        completedStage={completedStage}
        setPrevtStage={setPrevtStage}
      />
      <ProgressTitleStage
        stage={currentStage}
        isQuiz={isQuiz}
        answeredQuestions={answeredQuestions}
      />
      <Stages
        stage={currentStage}
        nextStage={nextStage}
        addValues={addValues}
        docId={docId}
        setDocId={setDocId}
        values={values}
        isQuiz={isQuiz}
        setQuizAnsvers={setQuizAnsvers}
        setRightAnsweredQuestions={setRightAnsweredQuestions}
        percentAnswers={percentAnswers}
        setQuizPercentAnswers={setQuizPercentAnswers}
      />
    </PollMainWrapper>
  )
}
