export const checkReady = (value, optionInverseValue = '') => {
  let ready = false
  if (typeof value) {
    if (typeof value === 'string' || typeof value === 'number') {
      if (value !== '') {
        ready = true
      } else {
        ready = false
      }
    }
    if (typeof value === 'object') {
      if (value.length > 0) {
        ready = true
      } else {
        ready = false
      }
    }
    if (typeof optionInverseValue === 'object') {
      if (optionInverseValue.length > 0) {
        ready = false
      } else {
        ready = true
      }
    }
  }

  return ready.toString()
}

export const cropLengthDigits = (value, length = 3) => {
  let cropValue = value

  if (value.length > length) {
    cropValue = cropValue.slice(0, length)
  }

  return cropValue
}
