module.exports = `
    var wiek = 30;
    var wzrost = 170;
    var masa = 70;
    var BMI = 0.0;
    var AST = 30.0;
    var NAST = 40.0;
    var ALT= 30.0;
    var PLT = 240;
    var ALB = 40;
    var DIAB = 0;
    var FIB = 0.0;
    var kalk = 1;
    var APRI = 0.0;
    var NALFD =0.0;
    var BARD, BS1, BS2, BS3 = 0.0;

    var n = 0;


    var polewiek = document.getElementById("polewiek");
    var polewzrost = document.getElementById("polewzrost");
    var polemc = document.getElementById("polemc");
    var polediab = document.getElementById("polediab");
    var poleast = document.getElementById("poleast");
    var npoleast = document.getElementById("npoleast");
    var polealt = document.getElementById("polealt");
    var poleplt = document.getElementById("poleplt");
    var polealb = document.getElementById("polealb");
    var WYNIK1 = document.getElementById("wyn1");
    var WYNIK2 = document.getElementById("wyn2");
    var LEWO = document.getElementById("lewo");
    var PRAWO = document.getElementById("prawo");
    var TYTUL = document.getElementById("tytul");
    var zajawka = document.getElementById("zajawka");

    var danealb = document.getElementById("danealb");
    var danediab = document.getElementById("danediab");
    var danewiek = document.getElementById("danewiek");
    var daneast = document.getElementById("daneast");
    var danewzrost = document.getElementById("danewzrost");
    var danemasa = document.getElementById("danemasa");
    var danenormaast = document.getElementById("danenormaast");
    var danealt = document.getElementById("danealt");
    var daneplt = document.getElementById("daneplt");
    var W5 = document.getElementById("w5");
    var BIBL1 = document.getElementById("bibl1");
    var KOM1 = document.getElementById("kom1");

    var DIABN = document.getElementById("diabn");
    var DIABT = document.getElementById("diabt");

    var WZMD1 = document.getElementById("wzmd1");
    var WZMJ1 = document.getElementById("wzmj1");
    var WZPD1 = document.getElementById("wzpd1");
    var WZPJ1 = document.getElementById("wzpj1");

    var MCMD1 = document.getElementById("mcmd1");
    var MCMJ1 = document.getElementById("mcmj1");
    var MCPD1 = document.getElementById("mcpd1");
    var MCPJ1 = document.getElementById("mcpj1");

    var ALBMD1 = document.getElementById("albmd1");
    var ALBMJ1 = document.getElementById("albmj1");
    var ALBPD1 = document.getElementById("albpd1");
    var ALBPJ1 = document.getElementById("albpj1");

    var DM1 = document.getElementById("dm1");
    var JM1 = document.getElementById("jm1");
    var DP1 = document.getElementById("dp1");
    var JP1 = document.getElementById("jp1");

    var DM2 = document.getElementById("dm2");
    var JM2 = document.getElementById("jm2");
    var CM2 = document.getElementById("cm2");
    var DP2 = document.getElementById("dp2");
    var JP2 = document.getElementById("jp2");
    var CP2 = document.getElementById("cp2");

    var NDM2 = document.getElementById("ndm2");
    var NJM2 = document.getElementById("njm2");
    var NCM2 = document.getElementById("ncm2");
    var NDP2 = document.getElementById("ndp2");
    var NJP2 = document.getElementById("njp2");
    var NCP2 = document.getElementById("ncp2");

    var DM3 = document.getElementById("dm3");
    var JM3 = document.getElementById("jm3");
    var CM3 = document.getElementById("cm3");
    var DP3 = document.getElementById("dp3");
    var JP3 = document.getElementById("jp3");
    var CP3 = document.getElementById("cp3");

    var DM4 = document.getElementById("dm4");
    var JM4 = document.getElementById("jm4");
    var DP4 = document.getElementById("dp4");
    var JP4 = document.getElementById("jp4");

    var IFG = document.getElementById("ifg");


    DM1.onclick = function (){fdm1()}
    JM1.onclick = function (){fjm1()}
    DP1.onclick = function (){fdp1()}
    JP1.onclick = function (){fjp1()}

    DM2.onclick = function (){fdm2()}
    JM2.onclick = function (){fjm2()}
    CM2.onclick = function (){fcm2()}
    DP2.onclick = function (){fdp2()}
    JP2.onclick = function (){fjp2()}
    CP2.onclick = function (){fcp2()}

    NDM2.onclick = function (){fndm2()}
    NJM2.onclick = function (){fnjm2()}
    NCM2.onclick = function (){fncm2()}
    NDP2.onclick = function (){fndp2()}
    NJP2.onclick = function (){fnjp2()}
    NCP2.onclick = function (){fncp2()}

    DM3.onclick = function (){fdm3()}
    JM3.onclick = function (){fjm3()}
    CM3.onclick = function (){fcm3()}
    DP3.onclick = function (){fdp3()}
    JP3.onclick = function (){fjp3()}
    CP3.onclick = function (){fcp3()}

    DM4.onclick = function (){fdm4()}
    JM4.onclick = function (){fjm4()}
    DP4.onclick = function (){fdp4()}
    JP4.onclick = function (){fjp4()}

    WZMD1.onclick = function (){fwzmd1()}
    WZMJ1.onclick = function (){fwzmj1()}
    WZPD1.onclick = function (){fwzpd1()}
    WZPJ1.onclick = function (){fwzpj1()}

    MCMD1.onclick = function (){fmcmd1()}
    MCMJ1.onclick = function (){fmcmj1()}
    MCPD1.onclick = function (){fmcpd1()}
    MCPJ1.onclick = function (){fmcpj1()}


    ALBMD1.onclick = function (){falbmd1()}
    ALBMJ1.onclick = function (){falbmj1()}
    ALBPD1.onclick = function (){falbpd1()}
    ALBPJ1.onclick = function (){falbpj1()}

    LEWO.onclick = function (){flewo()}
    PRAWO.onclick = function (){fprawo()}

    DIABN.onclick = function (){fdiabn()}
    DIABT.onclick = function (){fdiabt()}

    function pisz(){
    switch (kalk){
    case 1:
    TYTUL.value = "KALKULATOR FIB-4";
    danewiek.style.display = "block";
    daneast.style.display = "block";
    danenormaast.style.display = "none";
    danealt.style.display = "block";
    daneplt.style.display = "block";
    danewzrost.style.display = "none";
    danemasa.style.display = "none";
    danealb.style.display = "none";
    danediab.style.display = "none";
    break;
    case 2:
    TYTUL.value = "KALKULATOR APRI";
    danewiek.style.display = "none";
    daneast.style.display = "block";
    danenormaast.style.display = "block";
    danealt.style.display = "none";
    daneplt.style.display = "block";
    danewzrost.style.display = "none";
    danemasa.style.display = "none";
    danealb.style.display = "none";
    danediab.style.display = "none";
    break;
    case 3:
    TYTUL.value = "KALKULATOR NALFD";
    IFG.innerHTML = "IFG lub cukrzyca"
    danewiek.style.display = "block";
    daneast.style.display = "block";
    danenormaast.style.display = "none";
    danealt.style.display = "block";
    daneplt.style.display = "block";
    danewzrost.style.display = "block";
    danemasa.style.display = "block";
    danealb.style.display = "block";
    danediab.style.display = "block";
    break;

    case 4:
    TYTUL.value = "KALKULATOR BARD";
    IFG.innerHTML = "rozpoznana cukrzyca"
    danewiek.style.display = "none";
    daneast.style.display = "block";
    danenormaast.style.display = "none";
    danealt.style.display = "block";
    daneplt.style.display = "none";
    danewzrost.style.display = "block";
    danemasa.style.display = "block";
    danealb.style.display = "none";
    danediab.style.display = "block";
    break;
    }


    polewiek.value=wiek;

    if (ALB < 35 || ALB > 55){
    polealb.style.backgroundColor= "#FCC";
    }
    else
    {
    polealb.style.backgroundColor="#CFC";
    }


    if (DIAB==1){
    polediab.style.backgroundColor= "#FCC";
    }
    else
    {
    polediab.style.backgroundColor="#CFC";
    }

    if (AST < 5 || AST > 40){
    poleast.style.backgroundColor= "#FCC";
    }
    else
    {
    poleast.style.backgroundColor="#CFC";
    }

    if (ALT < 5 || ALT > 40){
    polealt.style.backgroundColor= "#FCC";
    }
    else
    {
    polealt.style.backgroundColor="#CFC";
    }
    if (PLT < 140 || PLT > 440){
    poleplt.style.backgroundColor= "#FCC";
    }
    else
    {
    poleplt.style.backgroundColor="#CFC";
    }
    poleast.value=AST.toFixed(1);
    npoleast.value=NAST.toFixed(1);
    polealt.value=ALT.toFixed(1);
    poleplt.value= PLT;
    polealb.value= ALB;
    polewzrost.value=wzrost;
    polemc.value=masa;
    polediab.value=DIAB;

    /*obliczenia*/

    APRI = AST/NAST/PLT*100;

    FIB = (wiek * AST) / (PLT * Math.pow(10,n) * Math.sqrt(ALT));

    BMI = masa/Math.pow((wzrost/100), 2);


    if(BMI>=28){
    BS1= 1;
    }
    else{
    BS1=0;
    }

    if((AST/ALT)>= 0.8){
    BS2=2;
    }
    else{
    BS2=0;
    }

    NALFD = -1.675 + (0.037*wiek) + (0.094*BMI) + (1.13*DIAB) + (0.99*(AST/ALT)) - (0.013*PLT) - (0.66*ALB/10);


    BARD = BS1+BS2+BS3;

    /* koniec obliczeń */

    switch (kalk){
    case 1:
    zajawka.innerHTML="Obliczanie wskaźnika FIB-4";
    if (FIB < 1.3){
    WYNIK1.innerHTML=" <h1>FIB-4 = " + FIB.toFixed(2) +"</h1>";
    WYNIK2.innerHTML="W populacjach takich jak polska wyniki <1,3 cechują się wysoką wartością predykcyjną ujemną dla rozpoznania zaawansowanego włóknienia wątroby równą 96%. To znaczy, że 96% osób mających wynik <1,3 rzeczywiście nie będzie miało zaawansowanego włóknienia. Spośród chorych z zaawansowanym włóknieniem, będzie jednak 26% uzyskujących tak niski wynik."
    }

    if(FIB >=1.3 && FIB <= 2.67){
    WYNIK1.innerHTML=" <h1>FIB-4 = " + FIB.toFixed(2) +"</h1>";
    WYNIK2.innerHTML="Zgodnie z piśmiennictwem dotyczącym wskaźnika FIB-4 wyniki w przedziale 1,3-2,67 określa się jako nieokreślone i nie pozwalają one na wyciągnięcie wiarygodnych wniosków dotyczących zaawansowania włóknienia. Wskazana ocena stanu wątroby w badaniach krwi i USG jamy brzusznej."
    }

    if (FIB > 2.67){
    WYNIK1.innerHTML=" <h1>FIB-4 = " + FIB.toFixed(2) +"</h1>";
    WYNIK2.innerHTML="Wyniki >2,67 w populacji o niskiej prewalencji zaawansowanego włóknienia jaką jest ogólna populacja Polski cechują się wartością predykcyjną dodatnią dla zaawansowanego włóknienia 60%, czułością 34% i swoistością 98%. U osób z wynikami >2,67 należy sprawdzić biochemiczną aktywność enzymów wątrobowych oraz wykonać badanie obrazowe tego narządu. Należy pamiętać, że 40% osób w grupie osób z wynikiem wyższym niż 2,67 będą stanowić osoby bez zawansowanego włóknienia, jednakże spośród wszystkich osób bez zaawansowanego włóknienia 98% będzie się mieścić poniżej progu 2,67."
    }
    BIBL1.innerHTML = "<sub>1.	Shah AG, Lydecker A, Murray K, et al. Comparison of noninvasive markers of fibrosis in patients with nonalcoholic fatty liver disease. Clin Gastroenterol Hepatol. 2009;7(10):1104-12.</sub><h5> Wzór Fibrosis 4 Score = (wiek [lata]*AST) / (liczba płytek[×10<sup>9</sup>/L]*√(ALT))</h5>";
    KOM1.innerHTML ="";
    break;

    case 2:
    zajawka.innerHTML="Obliczanie wskaźnika punktowego APRI";
    WYNIK1.innerHTML=" <h1>APRI = " + APRI.toFixed(2) +"</h1>";
    if (APRI < 0.5){
    WYNIK2.innerHTML="Około 40-50% pacjentów ze zdrową wątrobą mieści się w przedziale wyników poniżej 0,5. Wyniki poniżej 0,5 cechuje również ok. 10% chorych ze znaczącym włóknieniem, a 90% osób z tej grupy osiąga wyniki powyżej tego progu. Prawdopodobieństwo, że chory w tym przedziale ma zdrową wątrobę wynosi 80-90%.";
    }

    if(APRI >=0.5 && APRI <=1.5){
    WYNIK2.innerHTML="Wyniki w przedziale 0,5-1,5 określane są jako niedeterminujące. W przedziale tym zdarzają się zarówno chorzy z zaawansowanym włóknieniem, ale bez wyraźnie podwyższonej aktywności AST oraz bez znacznego stopnia małopłytkowości, jak również osoby ze zdrową wątrobą.";
    }

    if(APRI >1.5 && APRI < 2){
    WYNIK2.innerHTML="Wyniki powyżej 1,5 cechują ok. 50% osób z zaawansowanym włóknieniem (stan przed marskością i marskość). W grupie z takimi wynikami znajdują się również osoby nie posiadające zaawansowanego włóknienia, jednak istnieje 87% prawdopodobieństwo, że taka osoba będzie miała wynik <1,5.";
    }
    if(APRI >=2){
    WYNIK2.innerHTML="Wyniki powyżej 2,0 są sugestywne dla marskości wątroby. W dotychczasowych badaniach wartość predykcyjna dodatnia takich wyników w odniesieniu do potwierdzenia obecności marskości wynosiła 82%. Wyniki APRI>2,0 charakteryzowały 46% chorych z marskością i jedynie 9% osób bez marskości. Wskazana ocena stanu wątroby drogą badań laboratoryjnych i USG jamy brzusznej.";
    }
    BIBL1.innerHTML = "<sub>1.	Lin ZH, Xin YN, Dong QJ, et al. Performance of the aspartate aminotransferase-to-platelet ratio index for the staging of hepatitis C-related fibrosis: an updated meta-analysis. Hepatology. 2011;53:726-36. <br>2.	Khan DA, Fatima Tuz Z, Khan FA, Mubarak A. Evaluation of diagnostic accuracy of APRI for prediction of fibrosis in hepatitis C patients. J Ayub Med Coll Abbottabad 2008; 20: 122-126.<br>3.	Wai CT, Greenson JK, Fontana RJ, i in. A simple noninvasive index can predict both signiﬁcant ﬁbrosis and cirrhosis in patients with chronic hepatitis C. Hepatology. 2003;38:518–526.</sub><h5>Wzór APRI = (AST [w IU/L]) / (górna granica normy AST  [w IU/L]) / (liczba płytek [×10<sup>9</sup>/L])</h5>";
    KOM1.innerHTML ="";
    break;
    case 3:
    zajawka.innerHTML="Obliczanie wskaźnika punktowego NALFD";
    if(NALFD < -1.455){
    WYNIK1.innerHTML=" <h1>NALFD = "+ NALFD.toFixed(2) + "</h1>" ;
    WYNIK2.innerHTML="Wartość predykcyjna ujemna wyniku poniżej -1,455 dla zaawansowanego włóknienia wynosi ok. 90%. Wyniki te osiąga ok. 75% osób bez zaawansowanego włóknienia, a u 25% stwierdza się wyniki wyższe niż -1,455. Należy jednak pamiętać, że test się myli u ok. 10% osób z tym wynikiem. Dlatego warto przy kolejnej okazji sprawdzić ponownie stan wątroby.";
    }
    if(NALFD >= -1.455 && NALFD <= 0.675){
    WYNIK1.innerHTML=" <h1>NALFD = "+ NALFD.toFixed(2) + "</h1>" ;
    WYNIK2.innerHTML="Przedział między -1,455, a 0,675 ocenia się jako niedeterminujący. W tej grupie znajduje się duża grupa osób  bez zaawansowanego włóknienia wątroby jak i osoby z zaawansowanym włóknieniem. Aby ocenić stan wątroby konieczne są badania laboratoryjne i obrazowe";
    }
    if(NALFD >0.675){
    WYNIK1.innerHTML=" <h1>NALFD = "+ NALFD.toFixed(2) + "</h1>" ;
    WYNIK2.innerHTML="Prawdopodobieństwo, że osoba z niealkoholową stłuszczeniową chorobą wątroby (NAFLD) osiągająca wynik >0,675 ma zaawansowane włóknienie jest wysokie i wynosi 82-90%. Próg ten prawidłowo identyfikował 43-50% osób z zaawansowanym NAFLD, pozostała połowa osiągała wyniki niższe. Spośród osób bez włóknienia wątroby, ponad 95% osób zdrowych osiąga wyniki niższe niż 0,676. U chorych z takimi wynikiem wskazane wydaje się sprawdzenie stanu wątroby badaniami laboratoryjnymi i obrazowymi. ";
    }
    BIBL1.innerHTML = "<sub>Angulo P1, Hui JM, Marchesini G, Bugianesi E, George J, Farrell GC, Enders F, Saksena S, Burt AD, Bida JP, Lindor K, Sanderson SO, Lenzi M, Adams LA, Kench J, Therneau TM, Day CP. The NAFLD fibrosis score: a noninvasive system that identifies liver fibrosis in patients with NAFLD. Hepatology. 2007 Apr;45(4):846-54.</sub> <h5>Wzór NAFLD = -1.675 + (0.037*wiek [lata]) + (0.094*BMI [kg/m2]) + (1.13*IFG/cukrzyca [tak = 1, nie = 0]) + (0.99* iloraz AST/ALT) – (0.013* liczba płytek [×10<sup>9</sup>/L]) – (0.66*poziom albumin [g/dl])</h5>";
    KOM1.innerHTML ="";
    break;

    case 4:
    zajawka.innerHTML="Obliczanie wskaźnika punktowego BARD";

    WYNIK1.innerHTML=" <h1>BARD = "+BARD + " pkt. </h3>" ;
    if(BARD<2){
    WYNIK2.innerHTML="Wynik BARD w przedziale 0-1 cechuje się negatywną wartością predykcyjną równą 96% w wykluczaniu zaawansowanego włóknienia (stopień 3 i 4 skali włóknienia, gdzie 4 oznacza marskość).";
    }
    if(BARD>=2){
    WYNIK2.innerHTML="Uzyskanie 2 do 4 punktów wskaźnika BARD jest związane z 17-krotnie większym ryzykiem zaawansowanego włóknienia wątroby niż w grupie osób ze stłuszczeniem i punktacją 0-1. Wartość predykcyjna dodatnia, a więc prawdopodobieństwo, że chory wynikiem 2-4 punkty rzeczywiście ma zaawansowane włóknienie wynosi według pracy oryginalnej 43%, a w populacji polskiej może być nawet wyższe i sięgać 68%. ";
    }
    BIBL1.innerHTML = "<sub>1.	Harrison, S. A., Oliver, D., Arnold, H. L., Gogia, S., & Neuschwander-Tetri, B. A. (2008). Development and validation of a simple NAFLD clinical scoring system for identifying patients without advanced disease. Gut, 57(10), 1441–1447.2.<br> 2.	Cichoż-Lach, H., Celiński, K., Prozorow-Król, B., Swatek, J., Słomka, M., & Lach, T. (2012). The BARD score and the NAFLD fibrosis score in the assessment of advanced liver fibrosis in nonalcoholic fatty liver disease. Medical Science Monitor, 18(12), CR735–CR740.  </sub><h5>Wynik BARD składa się z 3 zmiennych o nastepującej punktacji:</h5>   1- BMI&nbsp;≥&nbsp;28&nbsp;=&nbsp;1&nbsp;,&nbsp;BMI&nbsp;<&nbsp;28&nbsp;=&nbsp;0 <br>  2- stosunek AST/ALT (AAR)&nbsp;≥&nbsp;0,8&nbsp;=&nbsp;2&nbsp; , (AAR)&nbsp;<&nbsp;0,8&nbsp;=&nbsp;0  <br>  3- rozpoznana cukrzyca&nbsp;tak&nbsp;=&nbsp;1&nbsp;,&nbsp; nie&nbsp;=&nbsp;0  <h5>  Możliwy wynik waha się od 0 do 4 punktów.</h5>";
    KOM1.innerHTML ="";
    break;
    }



    }



    /* wiek */
    function fdm1(){
    wiek = wiek-10;
    if(wiek<1){wiek=1;}
    pisz();
    }

    function fjm1(){
    wiek--;
    if(wiek<1){wiek=1;}
    pisz();
    }

    function fdp1(){
    wiek = wiek+10;
    if(wiek>99){wiek=99;}
    pisz();
    }

    function fjp1(){
    wiek++;
    if(wiek>99){wiek=99;}
    pisz();
    }


    /* wzrost */
    function fwzmd1(){
    wzrost = wzrost-10;
    if(wzrost<100){wzrost=100;}
    pisz();
    }

    function fwzmj1(){
    wzrost--;
    if(wzrost<100){wzrost=100;}
    pisz();
    }

    function fwzpd1(){
    wzrost = wzrost+10;
    if(wzrost>220){wzrost=220;}
    pisz();
    }

    function fwzpj1(){
    wzrost++;
    if(wzrost>220){wzrost=220;}
    pisz();
    }

    /* ALB */
    function falbmd1(){
    ALB = ALB-10;
    if(ALB<5){ALB=5;}
    pisz();
    }

    function falbmj1(){
    ALB--;
    if(ALB<5){ALB=5;}
    pisz();
    }

    function falbpd1(){
    ALB = ALB+10;
    if(ALB>120){ALB=120;}
    pisz();
    }

    function falbpj1(){
    ALB++;
    if(ALB>120){ALB=120;}
    pisz();
    }



    /* masa */
    function fmcmd1(){
    masa = masa-10;
    if(masa<30){masa=30;}
    pisz();
    }

    function fmcmj1(){
    masa--;
    if(masa<30){masa=30;}
    pisz();
    }

    function fmcpd1(){
    masa = masa+10;
    if(masa>220){masa=220;}
    pisz();
    }

    function fmcpj1(){
    masa++;
    if(masa>220){masa=220;}
    pisz();
    }

    /* AST */
    function fdm2(){
    AST = AST-10;
    if(AST<10){AST=10;}
    pisz();
    }

    function fjm2(){
    AST--;
    if(AST<10){AST=10;}
    pisz();
    }

    function fcm2(){
    AST = AST-0.1;
    if(AST<10){AST=10;}
    pisz();
    }

    function fdp2(){
    AST = AST+10;
    if(AST>600){AST=600;}
    pisz();
    }

    function fjp2(){
    AST++;
    if(AST>600){AST=600;}
    pisz();
    }

    function fcp2(){
    AST = AST+0.1;
    if(AST>600){AST=600;}
    pisz();
    }

    /* norma dla AST */
    function fndm2(){
    NAST = NAST-10;
    if(NAST<4){NAST=4;}
    pisz();
    }

    function fnjm2(){
    NAST--;
    if(NAST<4){NAST=4;}
    pisz();
    }

    function fncm2(){
    NAST = NAST-0.1;
    if(NAST<4){NAST=4;}
    pisz();
    }

    function fndp2(){
    NAST = NAST+10;
    if(NAST>120){NAST=120;}
    pisz();
    }

    function fnjp2(){
    NAST++;
    if(NAST>120){NAST=120;}
    pisz();
    }

    function fncp2(){
    NAST = NAST+0.1;
    if(NAST>120){NAST=120;}
    pisz();
    }


    /* ALT */
    function fdm3(){
    ALT = ALT-10;
    if(ALT<10){ALT=10;}
    pisz();
    }

    function fjm3(){
    ALT--;
    if(ALT<10){ALT=10;}
    pisz();
    }

    function fcm3(){
    ALT = ALT-0.1;
    if(ALT<10){ALT=10;}
    pisz();
    }

    function fdp3(){
    ALT = ALT+10;
    if(ALT>600){ALT=600;}
    pisz();
    }

    function fjp3(){
    ALT++;
    if(ALT>600){ALT=600;}
    pisz();
    }

    function fcp3(){
    ALT = ALT+0.1;
    if(ALT>600){ALT=600;}
    pisz();
    }


    /* płytki (PLT) */
    function fdm4(){
    PLT = PLT-10;
    if(PLT<10){PLT=10;}
    pisz();
    }

    function fjm4(){
    PLT--;
    if(PLT<10){PLT=10;}
    pisz();
    }

    function fdp4(){
    PLT = PLT+10;
    if(PLT>900){PLT=900;}
    pisz();
    }

    function fjp4(){
    PLT++;
    if(PLT>900){PLT=900;}
    pisz();
    }

    /* zmiana typu kalkulatora */
    function flewo (){
    kalk--;
    if(kalk < 1){kalk= 1;}
    pisz();
    }

    function fprawo (){
    kalk++;
    if(kalk > 4){kalk= 4;}
    pisz();
    }


    /* TAK/NIE dla cukrzycy */
    function fdiabn (){
    DIAB = 0;
    BS3=0;
    pisz();
    }

    function fdiabt (){
    DIAB = 1;
    BS3 = 1;
    pisz();
    }
`
