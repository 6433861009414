import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PollMain from '../components/poll/poll-main'
import Footer from '../components/footer'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Wątroba się sprawdza - Essentiale Forte" />
      <PollMain />
      <Footer />
    </Layout>
  )
}

export default IndexPage
