import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useFirebase } from 'gatsby-plugin-firebase'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { scroller } from 'react-scroll'
import { globalHistory } from '@reach/router'
import queryString from 'query-string'

import Bibliography from './bibliography'
import StageQuizContinue from './stage-quiz-continue'
import Continue from './continue'
import {
  FormGroupWrapper,
  FormHeader,
  FormHint,
  FormCustomBtnInput,
} from './form-tags'
import {
  PollStageWrapper,
  PollStageCol,
  PollStageContentWrapper,
  StageContainer,
  DownloadLink,
} from '../tags'

import { checkReady } from '../../lib/functions'

const StageWrapper = styled(PollStageWrapper)``

const Stage1Container = styled(StageContainer)`
  ${(props) =>
    props.isQuiz &&
    `
      overflow: hidden;
    `};
`

const StageCol = styled(PollStageCol)``

const StageContentWrapper = styled(PollStageContentWrapper)`
  ${(props) =>
    props.isQuiz &&
    `
      padding-top: 45px;
    `};
`

const StageDownloadWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 60px;
  @media (min-width: 992px) {
    justify-content: flex-end;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }
`

const StageColButtonWrapper = styled(StageCol)`
  @media (min-width: 576px) {
    display: none;
  }
`

const StageSummaryHelper = styled.p`
  font-size: 20px;
  font-weight: 700;
  padding-top: 55px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: -5px;
  @media (min-width: 576px) {
    display: none;
  }
`

const StageHeader = (props) => {
  const SignupSchema = Yup.object().shape({
    pollQuestion1: Yup.string().required('Wybierz jedną odpowiedź.'),
    pollQuestion2: Yup.string().required(
      'Wybierz wszystkie pasujące odpowiedzi lub wybierz nie wiem.'
    ),
    pollQuestion3: Yup.string().required(
      'Wybierz wszystkie pasujące odpowiedzi lub wybierz nie wiem.'
    ),
    pollQuestion4: Yup.string().required(
      'Wybierz wszystkie pasujące odpowiedzi lub wybierz nie wiem.'
    ),
    pollQuestion5: Yup.string().required('Wybierz jedną odpowiedź.'),
    pollQuestion6: Yup.string().required(
      'Wybierz wszystkie pasujące odpowiedzi lub wybierz nie wiem.'
    ),
  })

  const calculateAnsweredQuestions = (values) => {
    const answers = {
      pollQuestion1: ['niealkoholowa stłuszczeniowa choroba wątroby'],
      // pollQuestion2: ['tak', 'nie', 'tak, ale nie wiem dokładnie co to jest'],
      pollQuestion3: [
        'cukrzyca typu 2',
        'nadwaga',
        'otyłość',
        'podwyższone stężenie cholesterolu',
      ],
      pollQuestion4: [
        'marskość wątroby',
        'niewydolność wątroby prowadząca do konieczności jej przeszczepienia',
        'rak wątroby',
        'zwiększenie ryzyka śmierci z przyczyn sercowo-naczyniowych',
      ],
      pollQuestion5: [
        'zmęczenie',
        'zaburzenia koncentracji',
        'uczucie dyskomfortu w nadbrzuszu',
        'chory może w ogóle nie odczuwać objawów',
      ],
      pollQuestion6: ['10-30% Polaków'],
      pollQuestion7: [
        'istnieją odpowiednie metody leczenia stłuszczenia wątroby',
        'stłuszczenie może się cofnąć pod wpływem skutecznego leczenia',
        'stłuszczenie często wymaga zaangażowania wielu różnych sposobów leczenia',
      ],
    }

    let countAnswers = 0

    for (let key in answers) {
      const fieldsAnswers = answers[key].sort()
      let value = values[key]

      if (Array.isArray(value)) {
        value.sort()
      } else {
        value = [value]
      }

      value.forEach((elem) => {
        if (fieldsAnswers.includes(elem)) {
          countAnswers = countAnswers + 1
        }
      })
    }

    const percentAnswers = (countAnswers / 18) * 100

    props.setQuizPercentAnswers(percentAnswers)

    props.setRightAnsweredQuestions(countAnswers)
  }

  const data = useStaticQuery(graphql`
    query Stage1ConfigQuery {
      site {
        siteMetadata {
          counterDocId
        }
      }
    }
  `)
  useFirebase((firebase) => {
    let pollID = {}

    if (globalHistory.location.search) {
      pollID = queryString.parse(globalHistory.location.search, {
        arrayFormat: 'comma',
      })
    }

    if (Object.keys(props.values).length === 0 && !pollID['id']) {
      firebase
        .firestore()
        .collection('counters')
        .doc(data.site.siteMetadata.counterDocId)
        .update('count', firebase.firestore.FieldValue.increment(1))
    }
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.values}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (props.isQuiz === false) {
          setSubmitting(false)
          calculateAnsweredQuestions(values)
          props.setQuizAnsvers(true)

          scroller.scrollTo('stageTitleHeader', {
            duration: 1000,
            offset: -50,
            smooth: true,
          })

          if (process.browser && 'parentIFrame' in window) {
            window.parentIFrame.scrollTo(0, 0);
          }
        } else {
          setSubmitting(true)
          props.nextStage(values)
          props.setQuizAnsvers(false)
        }
      }}
    >
      {({ submitForm, errors, touched, values }) => {
        return (
          <Form>
            <Stage1Container isQuiz={props.isQuiz} className="firstStage">
              {props.header()}
              <StageWrapper>
                {props.isQuiz && (
                  <StageColButtonWrapper xs={12}>
                    <StageSummaryHelper>
                      Przejdź do kolejnego kroku aby sprawdzić stan swojej
                      wątroby!
                    </StageSummaryHelper>
                    <Continue
                      stage={1}
                      errorText={
                        Object.keys(errors).length > 0 && (
                          <p>
                            <strong>UWAGA!</strong> Aby przejść dalej prosimy
                            odpowiedzieć na wszystkie powyższe pytania.
                          </p>
                        )
                      }
                      handleClick={submitForm}
                      isQuiz={props.isQuiz}
                    />
                  </StageColButtonWrapper>
                )}
                <StageCol>
                  <StageContentWrapper isQuiz={props.isQuiz}>
                    <FormGroupWrapper>
                      <FormHeader>
                        1. Jaka jest najczęstsza obecnie przewlekła choroba
                        wątroby? <sup>1,2</sup>
                      </FormHeader>
                      <FormHint>Wybierz jedną odpowiedź.</FormHint>
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion1"
                        id="disease1"
                        value="alkoholowa choroba wątroby"
                        label="alkoholowa choroba wątroby"
                        answer={false}
                        answerNumber={1}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion1"
                        id="disease2"
                        value="niealkoholowa stłuszczeniowa choroba wątroby"
                        label="niealkoholowa stłuszczeniowa choroba wątroby"
                        answer={true}
                        answerNumber={1}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion1"
                        id="disease3"
                        value="wirusowe zapalenie wątroby typu B"
                        label="wirusowe zapalenie wątroby typu B"
                        answer={false}
                        answerNumber={1}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion1"
                        id="disease4"
                        value="wirusowe zapalenie watroby typu C"
                        label="wirusowe zapalenie watroby typu C"
                        answer={false}
                        answerNumber={1}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion1"
                        id="disease5"
                        value="nie wiem"
                        label="nie wiem"
                        answer={false}
                        answerNumber={1}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion1)}>
                      <FormHeader>
                        2. Czy spotkałeś się z określeniem niealkoholowa
                        stłuszczeniowa choroba wątroby?
                      </FormHeader>
                      <FormHint>Wybierz jedną odpowiedź.</FormHint>
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion2"
                        id="nonAlcoholicDisease1"
                        value="tak"
                        label="tak"
                        // answer={true}
                        // answerNumber={2}
                        isQuiz={props.isQuiz}
                        quizWithoutBG={true}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion2"
                        id="nonAlcoholicDisease2"
                        value="nie"
                        label="nie"
                        // answer={true}
                        // answerNumber={2}
                        isQuiz={props.isQuiz}
                        quizWithoutBG={true}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion2"
                        id="nonAlcoholicDisease3"
                        value="tak, ale nie wiem dokładnie co to jest"
                        label="tak, ale nie wiem dokładnie co to jest"
                        // answer={true}
                        // answerNumber={2}
                        isQuiz={props.isQuiz}
                        quizWithoutBG={true}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion2)}>
                      <FormHeader>
                        3. Co może prowadzić do niealkoholowej stłuszczeniowej
                        choroby wątroby? <sup>3</sup>
                      </FormHeader>
                      <FormHint>
                        Wybierz wszystkie pasujące odpowiedzi lub wybierz nie
                        wiem.
                      </FormHint>
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion3"
                        id="effects1"
                        value="cukrzyca typu 2"
                        label="cukrzyca typu 2"
                        disabled={checkReady(values.pollQuestion2)}
                        answer={true}
                        answerNumber={3}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion3"
                        id="effects2"
                        value="nadwaga"
                        label="nadwaga"
                        disabled={checkReady(values.pollQuestion2)}
                        answer={true}
                        answerNumber={3}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion3"
                        id="effects3"
                        value="otyłość"
                        label="otyłość"
                        disabled={checkReady(values.pollQuestion2)}
                        answer={true}
                        answerNumber={3}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion3"
                        id="effects4"
                        value="podwyższone stężenie cholesterolu"
                        label="podwyższone stężenie cholesterolu"
                        disabled={checkReady(values.pollQuestion2)}
                        answer={true}
                        answerNumber={3}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion3"
                        id="effects5"
                        value="nie wiem"
                        label="nie wiem"
                        disabled={checkReady(values.pollQuestion2)}
                        answer={false}
                        answerNumber={3}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion3)}>
                      <FormHeader>
                        4. Jakie mogą być konsekwencje stłuszczenia wątroby?{' '}
                        <sup>4,5,6</sup>
                      </FormHeader>
                      <FormHint>
                        Wybierz wszystkie pasujące odpowiedzi lub wybierz nie
                        wiem.
                      </FormHint>
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion4"
                        id="aftermath1"
                        value="marskość wątroby"
                        label="marskość wątroby"
                        disabled={checkReady(values.pollQuestion3)}
                        answer={true}
                        answerNumber={4}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion4"
                        id="aftermath2"
                        value="niewydolność wątroby prowadząca do konieczności jej przeszczepienia"
                        label="niewydolność wątroby prowadząca do konieczności jej przeszczepienia"
                        disabled={checkReady(values.pollQuestion3)}
                        answer={true}
                        answerNumber={4}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion4"
                        id="aftermath3"
                        value="rak wątroby"
                        label="rak wątroby"
                        disabled={checkReady(values.pollQuestion3)}
                        answer={true}
                        answerNumber={4}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion4"
                        id="aftermath4"
                        value="zwiększenie ryzyka śmierci z przyczyn sercowo-naczyniowych"
                        label="zwiększenie ryzyka śmierci z przyczyn sercowo-naczyniowych"
                        disabled={checkReady(values.pollQuestion3)}
                        answer={true}
                        answerNumber={4}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion4"
                        id="aftermath5"
                        value="nie wiem"
                        label="nie wiem"
                        disabled={checkReady(values.pollQuestion3)}
                        answer={false}
                        answerNumber={4}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion4)}>
                      <FormHeader>
                        5. Jakie mogą być objawy stłuszczenia wątroby?
                      </FormHeader>
                      <FormHint>
                        Wybierz wszystkie pasujące odpowiedzi lub wybierz nie
                        wiem.
                      </FormHint>
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion5"
                        id="symptoms1"
                        value="zmęczenie"
                        label="zmęczenie"
                        disabled={checkReady(values.pollQuestion4)}
                        answer={true}
                        answerNumber={5}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion5"
                        id="symptoms2"
                        value="zaburzenia koncentracji"
                        label="zaburzenia koncentracji"
                        disabled={checkReady(values.pollQuestion4)}
                        answer={true}
                        answerNumber={5}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion5"
                        id="symptoms3"
                        value="uczucie dyskomfortu w nadbrzuszu"
                        label="uczucie dyskomfortu w nadbrzuszu"
                        disabled={checkReady(values.pollQuestion4)}
                        answer={true}
                        answerNumber={5}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion5"
                        id="symptoms4"
                        value="chory może w ogóle nie odczuwać objawów"
                        label="chory może w ogóle nie odczuwać objawów"
                        disabled={checkReady(values.pollQuestion4)}
                        answer={true}
                        answerNumber={5}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion5"
                        id="symptoms5"
                        value="nie wiem"
                        label="nie wiem"
                        disabled={checkReady(values.pollQuestion4)}
                        answer={false}
                        answerNumber={5}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion5)}>
                      <FormHeader>
                        6. Ocenia się, że stłuszczeniowa choroba wątroby
                        dotyczy: <sup>7</sup>
                      </FormHeader>
                      <FormHint>Wybierz jedną odpowiedź.</FormHint>
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion6"
                        id="poll1"
                        value="do 10% Polaków"
                        label="do 10% Polaków"
                        disabled={checkReady(values.pollQuestion5)}
                        answer={false}
                        answerNumber={6}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion6"
                        id="poll2"
                        value="10-30% Polaków"
                        label="10-30% Polaków"
                        disabled={checkReady(values.pollQuestion5)}
                        answer={true}
                        answerNumber={6}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion6"
                        id="poll3"
                        value="30-50% Polaków"
                        label="30-50% Polaków"
                        disabled={checkReady(values.pollQuestion5)}
                        answer={false}
                        answerNumber={6}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion6"
                        id="poll4"
                        value="ponad 50 % Polaków"
                        label="ponad 50 % Polaków"
                        disabled={checkReady(values.pollQuestion5)}
                        answer={false}
                        answerNumber={6}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion6"
                        id="poll5"
                        value="nie wiem"
                        label="nie wiem"
                        disabled={checkReady(values.pollQuestion5)}
                        answer={false}
                        answerNumber={6}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    <FormGroupWrapper ready={checkReady(values.pollQuestion6)}>
                      <FormHeader>
                        7. Wskaż prawdziwe stwierdzenie dotyczące leczenia
                        niealkoholowej stłuszczeniowej choroby wątroby (NAFLD):
                      </FormHeader>
                      <FormHint>
                        Wybierz wszystkie pasujące odpowiedzi lub wybierz nie
                        wiem.
                      </FormHint>
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion7"
                        id="nafld1"
                        value="istnieją odpowiednie metody leczenia stłuszczenia wątroby"
                        label="istnieją odpowiednie metody leczenia stłuszczenia wątroby"
                        disabled={checkReady(values.pollQuestion6)}
                        answer={true}
                        answerNumber={7}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion7"
                        id="nafld2"
                        value="stłuszczenie może się cofnąć pod wpływem skutecznego leczenia"
                        label="stłuszczenie może się cofnąć pod wpływem skutecznego leczenia"
                        disabled={checkReady(values.pollQuestion6)}
                        answer={true}
                        answerNumber={7}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion7"
                        id="nafld3"
                        value="stłuszczenie jest łagodnym problemem i nie wymaga żadnego postępowania"
                        label="stłuszczenie jest łagodnym problemem i nie wymaga żadnego postępowania"
                        disabled={checkReady(values.pollQuestion6)}
                        answer={false}
                        answerNumber={7}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="checkbox"
                        name="pollQuestion7"
                        id="nafld4"
                        value="stłuszczenie często wymaga zaangażowania wielu różnych sposobów leczenia"
                        label="stłuszczenie często wymaga zaangażowania wielu różnych sposobów leczenia"
                        disabled={checkReady(values.pollQuestion6)}
                        answer={true}
                        answerNumber={7}
                        isQuiz={props.isQuiz}
                      />
                      <FormCustomBtnInput
                        type="radio"
                        name="pollQuestion7"
                        id="nafld5"
                        value="nie wiem"
                        label="nie wiem"
                        disabled={checkReady(values.pollQuestion6)}
                        answer={false}
                        answerNumber={7}
                        isQuiz={props.isQuiz}
                      />
                    </FormGroupWrapper>
                    {false && props.isQuiz && (
                      <StageDownloadWrapper>
                        <DownloadLink href="/test_wiedzy.pdf" target="_blank">
                          <span>Ściągnij poradnik</span>
                        </DownloadLink>
                      </StageDownloadWrapper>
                    )}
                  </StageContentWrapper>
                </StageCol>
                {props.isQuiz && (
                  <StageQuizContinue
                    handleClick={submitForm}
                    isQuiz={props.isQuiz}
                  />
                )}
              </StageWrapper>
            </Stage1Container>

            {!props.isQuiz && (
              <Continue
                stage={1}
                errorText={
                  Object.keys(errors).length > 0 && (
                    <p>
                      <strong>UWAGA!</strong> Aby przejść dalej prosimy
                      odpowiedzieć na wszystkie powyższe pytania.
                    </p>
                  )
                }
                handleClick={submitForm}
              />
            )}

            <Bibliography />
          </Form>
        )
      }}
    </Formik>
  )
}

export default StageHeader
