import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Collapse } from 'reactstrap'

import {
  MainContainer,
  CollapseWrapper,
  CollapseTitle,
  CollapseTitleWrapper,
  CollapseCard,
  CollapseCardBody,
} from '../tags'

const BibliographyWrapper = styled.div`
  margin-top: 80px;
`

const BibliographyContainer = styled(MainContainer)``

const BibliographyCollapseWrapper = styled(CollapseWrapper)`
  border: none;
`

const BibliographyTitle = styled(CollapseTitle)`
  padding-left: 15px !important;
  padding-right: 15px !important;
`

const BibliographyTitleWrapper = styled(CollapseTitleWrapper)``

const BibliographyCard = styled(CollapseCard)``

const BibliographyCardBody = styled(CollapseCardBody)`
  padding-left: 15px !important;
  padding-right: 15px !important;
`

const Bibliography = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <BibliographyWrapper>
      <BibliographyContainer>
        <Row>
          <Col>
            <BibliographyCollapseWrapper>
              <BibliographyTitle onClick={toggle} carret={isOpen.toString()}>
                <BibliographyTitleWrapper dataOpen={isOpen}>
                  Bibliografia
                </BibliographyTitleWrapper>
              </BibliographyTitle>
              <Collapse isOpen={isOpen}>
                <BibliographyCard>
                  <BibliographyCardBody>
                    <p>
                      <sup>1</sup>Tuyama AC, Chang CY. Non-alcoholic fatty liver
                      disease. J Diabetes 2012;4:266-80.
                    </p>
                    <p>
                      <sup>2</sup>Cusi K. Role of obesity and lipotoxicity in
                      the development of nonalcoholic steatohepatitis:
                      pathophysiology and clinical implications.
                      Gastroenterology 2012;142:711-725.
                    </p>
                    <p>
                      <sup>3</sup>Younossi ZM, Koenig AB, Abdelatif D, Fazel Y,
                      Henry L, Wymer M. Global epidemiology of nonalcoholic
                      fatty liver disease — meta-analytic assessment of
                      prevalence, incidence, and outcomes. Hepatology
                      2016;64:73-84.
                    </p>
                    <p>
                      <sup>4</sup>El-Serag HB, Tran T, Everhart JE. Diabetes
                      increases the risk of chronic liver disease and
                      hepatocellular carcinoma. Gastroenterology 2004;126:460-8.
                    </p>
                    <p>
                      <sup>5</sup>McCullough AJ. The epidemiology and risk
                      factors of NASH. In: Farrell GC, George J, Hall P,
                      McCullough AJ eds. Fatty liver disease: NASH and related
                      disorders. Malden, MA: Blackwell, 2005:23-37.
                    </p>
                    <p>
                      <sup>6</sup>Matteoni CA, Younossi ZM, Gramlich T, Boparai
                      N, Liu YC, McCullogh AJ. Nonalcoholic fatty liver disease:
                      a spectrum of clinical and pathological severity.
                      Gastroenterology 1999;116:1413-9.
                    </p>
                    <p>
                      <sup>7</sup>e-Akademia NAFLD, Hartleb M.,
                      https://www.termedia.pl/nafld/Prof-Marek-Hartleb-Stluszczenie-watroby-wystepuje-u-20-25-proc-Polakow,31922.html,
                      data dostępu 10.12.2019
                    </p>
                  </BibliographyCardBody>
                </BibliographyCard>
              </Collapse>
            </BibliographyCollapseWrapper>
          </Col>
        </Row>
      </BibliographyContainer>
    </BibliographyWrapper>
  )
}

export default Bibliography
