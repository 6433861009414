import React, { useState } from 'react'
import { useFirebase } from 'gatsby-plugin-firebase'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { globalHistory } from '@reach/router'
import queryString from 'query-string'

import Continue from './continue'
import StageComments from './stage-comments'
import { FormDescWrapper } from './form-tags'
import {
  PollStageWrapper,
  PollStageCol,
  PollStageContentWrapper,
  StageContainer,
} from '../tags'
import { PdfRenderLink } from './pdf-download'

// const FormLink = styled.a`
//   background-image: url(${require('../../images/pdf.svg')});
//   background-position: 100% center;
//   background-repeat: no-repeat;
//   background-size: auto 60px;
//   color: ${(props) => props.theme.textColorOrangeRed};
//   display: inline-block;
//   margin-bottom: 20px;
//   padding-right: 70px;

//   @media (max-width: 498px) {
//     background-position: left bottom;
//     margin-bottom: 0;
//     margin-top: 20px;
//     padding-bottom: 50px;
//     padding-right: 0;
//   }

//   @media (min-width: 499px) and (max-width: 991px) {
//     line-height: 60px;
//   }

//   &:hover {
//     color: ${(props) => props.theme.textColorOrangeRed};
//     text-decoration: underline;
//   }
// `

const StageWrapper = styled(PollStageWrapper)`
  .testCodeWrapper {
    cursor: pointer;
    margin: 40px 0;

    pre {
      background-image: url(${require('../../images/copy.svg')});
      background-position: 90% center;
      background-repeat: no-repeat;
      background-size: 32px auto;
      display: inline-block;
      padding-right: 85px !important;
    }
  }
`

const Stage4StageContainer = styled(StageContainer)`
  @media (max-width: 575px) {
    padding-right: 15px;
  }
`

const StageCol = styled(PollStageCol)`
  @media (min-width: 992px) {
    padding-top: 35px;
    ${(props) => props.stage === 4 && `padding-right: 8.5%;`}
  }
  @media (max-width: 575px) {
    ${(props) => props.stage === 4 && `padding-bottom: 50px;`}
  }
`

const StageContentWrapper = styled(PollStageContentWrapper)``

const StageFormDescWrapper = styled(FormDescWrapper)`
  p {
    font-size: 22px;
    font-weight: 300;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 18px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  strong {
    font-weight: 700;
  }

  a + p {
    margin-top: 40px;
  }
`

const ContinueWrapper = styled.div`
  padding-top: 20px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  /* @media (min-width: 576px) {
    display: none;
  } */
  .continueButton {
    @media (max-width: 360px) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
`

const Stage4HeaderCommentaryOr = styled.span`
  display: inline-block;
  width: 70px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding-top: 8px;
  @media (max-width: 991px) {
    position: static;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const Stage4HeaderTitleCommentary = styled.p`
  color: ${(props) => props.theme.textColor};
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 8px;
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const Stage4HeaderSubTitleCommentary = styled.p`
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 19px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 19px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 19px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const StageHeader = (props) => {
  const [dataValues, setDataValues] = useState(props.values)
  const [docId, setDocId] = useState(props.docId)

  useFirebase((firebase) => {
    let pollID = {}
    if (globalHistory.location.search) {
      pollID = queryString.parse(globalHistory.location.search, {
        arrayFormat: 'comma',
      })
    }

    if (!docId && typeof pollID['id'] !== 'undefined') {
      firebase
        .firestore()
        .collection('tests')
        .doc(pollID['id'])
        .get()
        .then((doc) => {
          if (!doc.exists) {
            alert('Nie znaleziono testu o takim identyfikatorze.')
            window.location.href = '/'
            return
          }
          let _docData = doc.data(),
            _docId = doc.id

          setDataValues(_docData)
          setDocId(_docId)

          props.addValues(_docData)
          props.setDocId(_docId)

          if (process.browser) {
            window.dataLayer.push({
              event: 'event',
              category: 'Back to test',
            })
          }
        })
        .catch((error) => {
          alert('Wystąpił błąd, spróbuj ponownie później.')
        })
    } else if (docId) {
      firebase
        .firestore()
        .collection('tests')
        .doc(docId)
        .update(dataValues)
    } else {
      firebase
        .firestore()
        .collection('tests')
        .add({
          ...dataValues,
          createdAt: new Date(),
        })
        .then((docRef) => {
          setDocId(docRef.id)
          props.setDocId(docRef.id)
        })
    }
  }, [])

  const commentsPrefix = () => {
    return (
      <>
        <h3>Stłuszczeniowa choroba wątroby</h3>
        <p>
          <strong>(NAFLD – ang. non-alcoholic fatty liver disease)</strong> jest
          najczęstszą przewlekłą chorobą wątroby w krajach rozwiniętych i
          dotyczy około 20-30% ich populacji. Szacuje się, że w Polsce choruje
          ponad 15% Polaków. Stłuszczenie wątroby jest silnie związane z
          narastającymi problemami otyłości, cukrzycy i zespołu metabolicznego.
          Najczęściej nie daje ono żadnych swoistych objawów (chorzy skarżą się
          na zmęczenie, zaburzenia koncentracji, uczucie dyskomfortu w
          nadbrzuszu). W badaniach dodatkowych stwierdza się podwyższoną
          echogeniczność miąższu wątroby w USG oraz okazjonalnie podwyższoną
          aktywność enzymów wątrobowych. U 1 na 5 osób ze stłuszczeniem choroba
          może prowadzić do marskości wątroby, której skutkiem może być
          niewydolność i rak tego narządu. Przede wszystkim jednak
          stłuszczeniowa choroba wątroby związana jest ze zwiększonym ryzykiem
          zgonu z przyczyn sercowo-naczyniowych. W leczeniu tej choroby
          wykorzystuje się wiele metod: dietę prowadzącą do chudnięcia ze
          szczególnym wskazaniem na dietę śródziemnomorską, zwiększenie
          aktywności fizycznej, farmakoterapię oraz leczenie chirurgiczne
          otyłości.
        </p>
      </>
    )
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={dataValues}
      onSubmit={(values) => {
        props.nextStage(values)
      }}
    >
      {({ values, submitForm }) => {
        return (
          <Form>
            <Stage4StageContainer>
              {props.header(submitForm)}
              <StageWrapper>
                <StageCol lg={7} stage={props.stage}>
                  <StageContentWrapper>
                    {docId && (
                      <StageFormDescWrapper>
                        <p>
                          <strong>Bardzo dziękujemy za poświęcony czas!</strong>
                        </p>
                        <p>
                          Mamy nadzieję, że nasza ankieta pozwoliła Ci
                          dowiedzieć się więcej o ryzyku związanym z NAFLD.
                        </p>
                        <p>
                          Pobierz formularz z&nbsp;wywiadem w&nbsp;kierunku
                          chorób wątroby, który ułatwi Ci&nbsp;konsultację
                          z&nbsp;lekarzem
                        </p>
                        <PdfRenderLink
                          href="/formularz_pacjent.pdf"
                          target="_blank"
                        >
                          <span>Ściągnij formularz wywiadu</span>
                        </PdfRenderLink>
                      </StageFormDescWrapper>
                    )}
                  </StageContentWrapper>
                </StageCol>
                <StageComments
                  header="Podsumowanie"
                  stage={props.stage}
                  docId={docId}
                  prefix={commentsPrefix()}
                  values={values}
                  btnContinue={<Continue stage={4} handleClick={submitForm} />}
                />
              </StageWrapper>
            </Stage4StageContainer>

          </Form>
        )
      }}
    </Formik>
  )
}

export default StageHeader
