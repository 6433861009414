import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Continue from './continue'
import StageComments from './stage-comments'
import {
  FormGroupWrapper,
  FormHeader,
  FormHint,
  FormCustomBtnInput,
  FormCustomCalculationInput,
} from './form-tags'
import {
  PollStageWrapper,
  PollStageCol,
  PollStageContentWrapper,
  StageContainer,
} from '../tags'

import { checkReady, cropLengthDigits } from '../../lib/functions'

const StageWrapper = styled(PollStageWrapper)``

const StageCol = styled(PollStageCol)``

const StageContentWrapper = styled(PollStageContentWrapper)``

const StageSchema = Yup.object().shape({
  weightChange: Yup.string().required('Wybierz jedną odpowiedź.'),
  physicalActivity: Yup.string().required('Wybierz jedną odpowiedź.'),
  sleepDay: Yup.string().required('Wybierz jedną odpowiedź.'),
  smokingCigarette: Yup.string().required('Wybierz jedną odpowiedź.'),
  ultrasound: Yup.string().required('Wybierz jedną odpowiedź.'),
  steatosis: Yup.string().when('ultrasound', {
    is: 'yes',
    then: Yup.string().required('Wybierz jedną odpowiedź.'),
    otherwise: Yup.string(),
  }),
  notDrink: Yup.string(),

  beer: Yup.number()
    .integer()
    .default(0)
    .min(0),
})

const StageHeader = (props) => (
  <Formik
    enableReinitialize={true}
    initialValues={props.values}
    validationSchema={StageSchema}
    validate={(values, touched) => {
      const errors = {}

      if (
        typeof values.smokingCigarette !== 'undefined' &&
        values.smokingCigarette === 'no'
      ) {
        values.ultrasound = ''
      }

      // if (
      //   typeof values.notDrink !== 'undefined' &&
      //   values.notDrink.length > 0
      // ) {
      //   values.beer = ''
      //   values.vine400 = ''
      //   values.vine750 = ''
      //   values.drink = ''
      //   values.alkohol100 = ''
      //   values.alkohol700 = ''
      // }

      return errors
    }}
    onSubmit={(values) => {
      props.nextStage(values)
    }}
  >
    {({ handleChange, errors, touched, values, submitForm, setFieldValue }) => (
      <>
        <StageContainer>
          {props.header()}
          <StageWrapper>
            <StageCol lg={6} stage={props.stage}>
              <StageContentWrapper>
                <Form>
                  <FormGroupWrapper>
                    <FormHeader>
                      Jak zmieniła się Twoja masa ciała w ciągu ostatnich 2 lat?
                    </FormHeader>
                    <FormHint>Wybierz jedną odpowiedź.</FormHint>
                    <FormCustomBtnInput
                      type="radio"
                      name="weightChange"
                      id="weightChange1"
                      value="wzrosła >5 kg"
                      label="wzrosła >5 kg"
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="weightChange"
                      id="weightChange2"
                      value="wzrosła 0-5 kg"
                      label="wzrosła 0-5 kg"
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="weightChange"
                      id="weightChange3"
                      value="nie zmieniła się"
                      label="nie zmieniła się"
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="weightChange"
                      id="weightChange4"
                      value="spadła 0-5 kg"
                      label="spadła 0-5 kg"
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="weightChange"
                      id="weightChange5"
                      value="spadła >5 kg"
                      label="spadła >5 kg"
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.weightChange)}>
                    <FormHeader>
                      Ile minut tygodniowo przeznaczasz na aktywność fizyczną?
                    </FormHeader>
                    <FormHint>Wybierz jedną odpowiedź.</FormHint>
                    <FormCustomBtnInput
                      type="radio"
                      name="physicalActivity"
                      id="physicalActivity1"
                      value="<30 min"
                      label="<30 min"
                      disabled={checkReady(values.weightChange)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="physicalActivity"
                      id="physicalActivity2"
                      value="30 min-60 min"
                      label="30 min-60 min"
                      disabled={checkReady(values.weightChange)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="physicalActivity"
                      id="physicalActivity3"
                      value="60-90 min"
                      label="60-90 min"
                      disabled={checkReady(values.weightChange)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="physicalActivity"
                      id="physicalActivity4"
                      value="90-150 min"
                      label="90-150 min"
                      disabled={checkReady(values.weightChange)}
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.physicalActivity)}>
                    <FormHeader>
                      Średnia liczba godzin snu w ciągu doby:
                    </FormHeader>
                    <FormHint>Wybierz jedną odpowiedź.</FormHint>
                    <FormCustomBtnInput
                      type="radio"
                      name="sleepDay"
                      id="sleepDay1"
                      value="<6h"
                      label="<6h"
                      disabled={checkReady(values.physicalActivity)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="sleepDay"
                      id="sleepDay2"
                      value="6-7,5h"
                      label="6-7,5h"
                      disabled={checkReady(values.physicalActivity)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="sleepDay"
                      id="sleepDay3"
                      value="7-9,5h"
                      label="7-9,5h"
                      disabled={checkReady(values.physicalActivity)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="sleepDay"
                      id="sleepDay4"
                      value="powyżej 9 godzin"
                      label="powyżej 9 godzin"
                      disabled={checkReady(values.physicalActivity)}
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.sleepDay)}>
                    <FormHeader>
                      Ilość wypijanego alkoholu w ciągu tygodnia
                    </FormHeader>
                    <FormHint>
                      Wpisz liczbę obok wszystkich pasujących pozycji, lub
                      zaznacz „nie piję alkoholu.”
                    </FormHint>
                    <FormCustomCalculationInput
                      type="number"
                      name="beer"
                      id="beer"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>piwo (500 ml)</span>
                        </>
                      }
                      value={values.beer}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.beer}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomCalculationInput
                      type="number"
                      name="vine400"
                      id="vine400"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>lampka wina 150 ml</span>
                        </>
                      }
                      value={values.vine400}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.vine400}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomCalculationInput
                      type="number"
                      name="vine750"
                      id="vine750"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>butelka wina 750 ml</span>
                        </>
                      }
                      value={values.vine750}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.vine750}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomCalculationInput
                      type="number"
                      name="drink"
                      id="drink"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>drink 150 ml</span>
                        </>
                      }
                      value={values.drink}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.drink}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomCalculationInput
                      type="number"
                      name="alkohol100"
                      id="alkohol100"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>alkohol 40% 100 ml</span>
                        </>
                      }
                      value={values.alkohol100}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.alkohol100}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomCalculationInput
                      type="number"
                      name="alkohol700"
                      id="alkohol700"
                      placeholder="Liczba jednostek"
                      label={
                        <>
                          <span className="multiplier">x</span>{' '}
                          <span>alkohol 40% butelka 700 ml</span>
                        </>
                      }
                      value={values.alkohol700}
                      defaultValue="0"
                      disabled={checkReady(values.sleepDay, values.notDrink)}
                      error={errors.alkohol700}
                      onChange={(e) => {
                        let value = e.target.value

                        value = cropLengthDigits(value, 3)
                        e.target.value = value
                        handleChange(e)
                        setFieldValue(value)
                      }}
                      stage={props.stage}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="notDrink"
                      id="notDrink"
                      value="0"
                      label="nie piję alkoholu"
                      disabled={checkReady(values.physicalActivity)}
                      isOne={true}
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.sleepDay)}>
                    <FormHeader>
                      Liczba wypalanych papierosów dziennie:
                    </FormHeader>
                    <FormHint>Wybierz jedną odpowiedź.</FormHint>
                    <FormCustomBtnInput
                      type="radio"
                      name="smokingCigarette"
                      id="smokingCigarette1"
                      value="nie palę"
                      label="nie palę"
                      disabled={checkReady(values.sleepDay)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="smokingCigarette"
                      id="smokingCigarette2"
                      value="<5 papierosów"
                      label="<5 papierosów"
                      disabled={checkReady(values.sleepDay)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="smokingCigarette"
                      id="smokingCigarette3"
                      value="5-15"
                      label="5-15"
                      disabled={checkReady(values.sleepDay)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="smokingCigarette"
                      id="smokingCigarette4"
                      value="15-25"
                      label="15-25"
                      disabled={checkReady(values.sleepDay)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="smokingCigarette"
                      id="smokingCigarette5"
                      value=">25 papierosów"
                      label=">25 papierosów"
                      disabled={checkReady(values.sleepDay)}
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.smokingCigarette)}>
                    <FormHeader>
                      Czy w ciągu ostatnich 2 lat miałaś/eś wykonywane USG jamy
                      brzusznej?
                    </FormHeader>
                    <FormHint>Wybierz jedną odpowiedź.</FormHint>
                    <FormCustomBtnInput
                      type="radio"
                      name="ultrasound"
                      id="ultrasound1"
                      value="nie"
                      label="Nie"
                      disabled={checkReady(values.smokingCigarette)}
                    />
                    <FormCustomBtnInput
                      type="radio"
                      name="ultrasound"
                      id="ultrasound2"
                      value="tak"
                      label="Tak"
                      disabled={checkReady(values.smokingCigarette)}
                    />
                    {values.ultrasound === 'tak' && (
                      <>
                        <FormHint>
                          Czy stwierdzono stłuszczenie wątroby lub podwyższoną
                          jej echogeniczność?
                        </FormHint>
                        <FormCustomBtnInput
                          type="radio"
                          name="steatosis"
                          id="steatosis1"
                          value="nie"
                          label="Nie"
                        />
                        <FormCustomBtnInput
                          type="radio"
                          name="steatosis"
                          id="steatosis2"
                          value="tak"
                          label="Tak"
                        />
                      </>
                    )}
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.ultrasound)}>
                    <FormHeader>Czy odczuwasz następujące objawy</FormHeader>
                    <FormHint>Wybierz wszystkie pasujące odpowiedzi.</FormHint>
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms1"
                      value="obniżenie poziomu energii"
                      label="obniżenie poziomu energii"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms2"
                      value="uczucie przewlekłego zmęczenia"
                      label="uczucie przewlekłego zmęczenia"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms3"
                      value="senność w ciągu dnia"
                      label="senność w ciągu dnia"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms4"
                      value="klopoty ze snem/z zaśnięciem"
                      label="klopoty ze snem/z zaśnięciem"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms5"
                      value="uczucie dyskomfortu w prawym podżebrzu"
                      label="uczucie dyskomfortu w prawym podżebrzu"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="symptoms"
                      id="symptoms6"
                      value="wzdęcia"
                      label="wzdęcia"
                      disabled={checkReady(values.ultrasound)}
                    />
                  </FormGroupWrapper>

                  <FormGroupWrapper ready={checkReady(values.ultrasound)}>
                    <FormHeader>Choroby dodatkowe</FormHeader>
                    <FormCustomBtnInput
                      type="checkbox"
                      name="additionalDiseases"
                      id="additionalDiseases1"
                      value="Nadciśnienie tętnicze"
                      label="Nadciśnienie tętnicze"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="additionalDiseases"
                      id="additionalDiseases2"
                      value="Cukrzyca typu 2"
                      label="Cukrzyca typu 2"
                      disabled={checkReady(values.ultrasound)}
                    />
                    <FormCustomBtnInput
                      type="checkbox"
                      name="additionalDiseases"
                      id="additionalDiseases3"
                      value="Hipercholesterolemia"
                      label="Hipercholesterolemia"
                      disabled={checkReady(values.ultrasound)}
                    />
                  </FormGroupWrapper>
                </Form>
              </StageContentWrapper>
            </StageCol>
            <StageComments values={values} />
          </StageWrapper>
        </StageContainer>
        <Continue
          stage={3}
          handleClick={submitForm}
          errorText={
            Object.keys(errors).length > 0 && (
              <p>
                <strong>UWAGA!</strong> Aby przejść dalej prosimy odpowiedzieć
                na wszystkie powyższe pytania.
              </p>
            )
          }
        />
      </>
    )}
  </Formik>
)

export default StageHeader
