import React from 'react'
import styled from 'styled-components'
import StickyBox from 'react-sticky-box'

import { FormHeaderCalculation } from './form-tags'
import Continue from './continue'
import {
  PollStageColHeaderCalculationMobile,
  PollStageColCalculation,
  PollStageCalculationWrapper,
} from '../tags'

const StageCalculationWrapper = styled(PollStageCalculationWrapper)`
  h3 {
    font-weight: 700;
    font-size: 2rem;
    color: rgb(225, 70, 26);
  }
`

const StageCalculationDescWrapper = styled.div`
  @media (min-width: 1200px) and (max-width: 1599px) {
    position: relative;
    left: -65px;
    width: calc(100% + 65px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    position: relative;
    left: -55px;
    width: calc(100% + 55px);
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
`

const StageColHeaderCalculationMobile = styled(
  PollStageColHeaderCalculationMobile
)`
  @media (max-width: 991px) {
    ${(props) => props.visibility === 'false' && `display: none;`}
  }
`

const StageHeaderFormHeaderCalculation = styled(FormHeaderCalculation)`
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.textColor};
  }
`

const StageColCalculation = styled(PollStageColCalculation)`
  @media (max-width: 991px) {
    ${(props) => props.visibility === 'false' && `display: none;`}
  }
  @media (max-width: 575px) {
    display: block;
  }
`

export default ({ handleClick }) => {
  return (
    <>
      <StageColHeaderCalculationMobile xs={12} visibility={false}>
        <StageHeaderFormHeaderCalculation>
          Przejdź do&nbsp;następnego
          <br /> etapu aby sprawdzić stan
          <br /> swojej wątroby!
        </StageHeaderFormHeaderCalculation>
      </StageColHeaderCalculationMobile>
      <StageColCalculation>
        <StageCalculationWrapper>
          <StickyBox offsetTop={20} offsetBottom={0}>
            <StageCalculationDescWrapper>
              <Continue stage={1} handleClick={handleClick} isQuiz={true} />
            </StageCalculationDescWrapper>
          </StickyBox>
        </StageCalculationWrapper>
      </StageColCalculation>
    </>
  )
}
