import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { MainContainer } from '../tags'

const ProgressTitleStageWrapper = styled.div`
  position: relative;
  margin-top: -20px;
  margin-bottom: 45px;
  @media (max-width: 575px) {
    margin-bottom: 35px;
  }
`

const ProgressTitleStageContainer = styled(MainContainer)``

const ProgressTitleStageHeader = styled.h1`
  text-align: center;
  line-height: 1.2;
  @media (max-width: 575px) {
    line-height: 0.8;
  }
`

const ProgressTitleStageHeaderWrapper = styled.span`
  display: inline-block;
  text-align: left;
  @media (max-width: 575px) {
    text-align: center;
  }
`

const ProgressTitleStageNumber = styled.span`
  display: inline-block;
  font-size: 29px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
`

const ProgressTitleStageName = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.textColorCherry};
  font-size: 54px;
  font-weight: 700;
  padding-left: 12px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 45px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 45px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 45px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 38px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    padding-left: 6px;
  }
`

const ProgressTitleQuizQuantity = styled.span`
  font-size: 60px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 50px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 50px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 43px;
  }
  @media (max-width: 575px) {
    font-size: 29px;
  }
`

const ProgressTitleStage = (props) => {
  let stage = props.stage ? props.stage : 1
  let stageTitle = ''

  if (props.isQuiz) {
    stage = 'quiz'
  }

  switch (stage) {
    case 2:
      stageTitle = 'Metryczka'
      break
    case 3:
      stageTitle = 'Styl życia i samopoczucie'
      break
    case 4:
      stageTitle = 'Podsumowanie wyników'
      break
    case 5:
      stageTitle = 'Kalkulator ryzyka'
      break
    case 'quiz':
      stageTitle = 'odpowiedzi poprawnych'
      break
    default:
      stageTitle = 'Co wiesz o stłuszczeniu wątroby?'
      break
  }

  return (
    <ProgressTitleStageWrapper id="stageTitleHeader">
      <ProgressTitleStageContainer>
        <Row>
          <Col>
            <ProgressTitleStageHeader>
              <ProgressTitleStageHeaderWrapper>
                <ProgressTitleStageNumber>
                  {!props.isQuiz && `Etap ${stage}`}
                  {props.isQuiz && `Wyniki`}
                </ProgressTitleStageNumber>
                <br />
                <ProgressTitleStageName>
                  {!props.isQuiz && stageTitle}
                  {props.isQuiz && (
                    <>
                      <ProgressTitleQuizQuantity>
                        {props.answeredQuestions}/18
                      </ProgressTitleQuizQuantity>{' '}
                      {stageTitle}
                    </>
                  )}
                </ProgressTitleStageName>
              </ProgressTitleStageHeaderWrapper>
            </ProgressTitleStageHeader>
          </Col>
        </Row>
      </ProgressTitleStageContainer>
    </ProgressTitleStageWrapper>
  )
}

export default ProgressTitleStage
